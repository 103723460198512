import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getAllTags, addTagToMovement } from "../smartfit_common/apihelper/apihelper";
import AddTag from "./AddTag";
import UpdateTag from "./UpdateTag";
import { Button } from 'flowbite-react'
import {Pagination, Table, Label, TextInput} from 'flowbite-react'

function AddTags ({relatedId, refreshTagList, addTagCallback}) {
  const [state, dispatch] = useContext(UserContext);
  const [tagList, setTagList] = useState({tags: []});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getAllTags(state, dispatch, undefined, page, filter).then(result => {
      setTagList(result);
      const calcMaxPage = () => {
        if (result.count <= result.tags.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.tags.length);
      }
      setMaxPage(calcMaxPage);
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [state, dispatch, page, filter]);

  const update = () => {
    getAllTags(state, dispatch, undefined, page, filter).then(result => {
      setTagList(result);
      const calcMaxPage = () => {
        if (result.count <= result.tags.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.tags.length);
      }
      setMaxPage(calcMaxPage);
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }

 

  const listTableItems = tagList.tags.map((tag, i) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={tag.id.toString()}>
         
          <UpdateTag 
            update={update} 
            tagId={tag.id} 
            tagName={tag.name}
            hideName={false}
            hideButtons={true}
          />
        </Table.Cell>
        <Table.Cell>
        <p>
            {tag.text !== "no description" && tag.text}
          </p>
        </Table.Cell>
        <Table.Cell>
          <div className="flex justify-end">
            <AddTag 
              refreshList={refreshTagList} 
              tagId={tag.id} 
              tagName={tag.name} 
              realtedId={relatedId} 
              addTagCallback={addTagCallback}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });
  

  const onPageChange = (p) => {
    setPage(p);
  }   
  
  return (
    <div>
      <div>
        <div className="mb-2 block">
          <Label
            value="Filter:"
          />
        </div>
        <TextInput
          type="text"
          value={filter}
          onChange={e => {
            setFilter(e.target.value);
            setPage(1);
          }}
        />
      </div>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
      <Table striped={true} hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Tag</Table.HeadCell>
          <Table.HeadCell>Description</Table.HeadCell>
          <Table.HeadCell><div className="flex justify-end">Actions</div></Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
        {listTableItems}
      </Table.Body>
    </Table>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
    </div>
  );
}
export default AddTags;