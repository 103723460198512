import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getUserDetail } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import EnterWod from "../enterwod/EnterWod";
import {Textarea, Button} from 'flowbite-react'


function User () {
  const [state, dispatch] = useContext(UserContext);
  const { id: paramId } = useParams();
  const [userDetail, setUserDetail] = useState({user: "not logged in"});
  const userId = paramId? paramId: state.userId;
  const [enterWod, setEnterWod] = useState(false);

  console.log(paramId);
  console.log(userId);
  useEffect(() => {
    getUserDetail(state, dispatch, undefined, userId).then(result => {
      setUserDetail(result);
      console.log(result);

    }).catch(error => {
      console.log(error.message);
    });
  }, [dispatch, userId, state]);

  const update = () => {
    getUserDetail(state, dispatch, userId).then(result => {
      setUserDetail(result);
      console.log(result);

    }).catch(error => {
      console.log(error.message);
    });
  }

  return (
    <div>
      <h4 class="text-xl font-bold dark:text-white">{userDetail.username}</h4>
      <div>Name: {userDetail.first_name + " " + userDetail.last_name}</div>
      <div>Email: {userDetail.email}</div>

      <Link to={"/wodlist/"+userId} state={{userName: userDetail.username}}>
        <h4 class="text-2xl font-bold dark:text-white">
          <p class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            Show my Workouts
          </p>
        </h4>
      </Link>
      <div className="mt-5">
        {enterWod && 
          <EnterWod 
            userId={userDetail.url}
            closeCallback={() => setEnterWod(false)}
          />
        }
        {!enterWod && 
          <Button
            onClick={() => setEnterWod(true)}
          >
            Enter Workout
          </Button>
        }
      </div>
    </div>
  );
}
export default User;