import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { SayHello } from '../App'
import NavBar from '../navbar/NavBar'
import { Outlet } from 'react-router-dom'
import { useContext, useState} from "react";
import UserContext from '../usercontext/UserContext';
import { Link, useLocation } from 'react-router-dom'
import {Button} from 'flowbite-react'




const UserIcon = () => 
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
  </svg>

function mapRouteHeader(location) {
  const path = location.pathname;

  if (path === '/') return "Dashboard";
  if (path.includes('/movements'))        return "Movements";
  if (path.includes('/tags'))             return "Tags";
  if (path.includes('/allwodlist'))       return "Workouts";
  if (path.includes('/wodpartlist'))      return "Workouts";
  if (path.includes('/wodlist'))          return "Workouts by User";
  if (path.includes('/woddetailmodify'))  return "Workout";
  if (path.includes('/ratewodpart'))      return "Rate Workouts";
  if (path.includes('/users'))            return "Users";
  if (path.includes('/generatorcustomsetting')) return "Generator Settings";
  if (path.includes('/settingslist')) return "Manage Generator Settings";

  return "Unknown Path"
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AppContainer() {
  const location = useLocation();
  const [state, dispatch] = useContext(UserContext);
  console.log(location);
  const auth = state.userId;
  const userAdmin = state?.userGroups?.includes("user_admin")? true: false;
  console.log(state.user);
  const user = {
    name: state.user,
    id: state.userId,
    imageUrl:""
  }

  const navigation = [
    { name: 'Dashboard', href: '/' , current: location.pathname === '/'? true: false },
    { name: 'Movements', href: '/movements', current: location.pathname === '/movements'? true: false },
    { name: 'Tags', href: '/tags', current: location.pathname === '/tags'? true: false },
    { name: 'Wod List', href: '/allwodlist', current: location.pathname === '/allwodlist'? true: false },
    { name: 'Rate Wods', href: '/ratewodpart', current: location.pathname === '/ratewodpart'? true: false },
    { name: 'Gen Settings', href: '/settingslist', current: location.pathname === '/settingslist'? true: false },
  ]

  if (auth && userAdmin) {
    navigation.push({ name: 'Users', href: '/users', current:  location.pathname === '/users'? true: false });
    //navigation.push({ name: 'Movements', href: '/movements', current: location.pathname === '/movements'? true: false });
    //navigation.push({ name: 'Tags', href: '/tags', current: location.pathname === '/tags'? true: false });
    //navigation.push({ name: 'Wod List', href: '/allwodlist', current: location.pathname === '/allwodlist'? true: false });

  }
  
  const userNavigation = [
    //{ name: 'Your Profile', href: '#' },
    //{ name: 'Settings', href: '#' },
    { name: 'Sign out', href: '/logout' },
  ]

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">

        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                    <div className="hidden md:block">
                      {/** delete token button 
                      
                      <Button onClick={() => dispatch({type: "deleteToken"})}>

                      </Button>
                      */}
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <Link onClick={() => {
                            //console.log(navigation);
                            //setNavigation(navigation[index].current);
                            //console.log(navigation);
                          }
                            } to={item.href}>
                            <span
                              key={item.name}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'px-3 py-2 rounded-md text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </span>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Notifications Icon 
                      <button
                        type="button"
                        className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      */}

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <UserIcon/>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link to={item.href}>
                                    <span
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                        >
                                      {item.name}
                                    </span>
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{user.id}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as={Link}
                        to={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h2 class="text-4xl font-extrabold dark:text-white">{mapRouteHeader(location)}</h2>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            <div className="px-4 py-6 sm:px-0">
              
              <Outlet/>
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </>
  )
}
