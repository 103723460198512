import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getAllWodList } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';

function AllWodsList () {
  const [state, dispatch] = useContext(UserContext);
  const [wodList, setWodlist] = useState({wods: []});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    getAllWodList(state, dispatch, page).then(result => {
      setWodlist(result);
      const calcMaxPage = () => {
        if (result.count <= result.wods.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.wods.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [state, page, dispatch]);

  const listItems = wodList.wods.map(wod => {
    return (
        <li key={wod.id.toString()}>
            {wod.wod_text}
        </li>
    );
  });
  
  const loadNext = () => {
    if (wodList.next === null)
      return;
    if (wodList.wods.length === 0)
      return;
    if (page >= maxPage) {
      setPage(maxPage);
      return;
    }
    setPage(page + 1);
  }

  const loadPrev = () => {
    if (page <= 1) return;
    setPage(page - 1);
  }

  return (
    <div>
      <h1>All Wods List</h1>
      <ul>
        {listItems}  
      </ul>
      <button
        onClick={loadNext}
      >
        next
      </button>
      <button
        onClick={loadPrev}
      >
        previous
      </button>
      <br/>
      Page: {page} of {maxPage}
      <br/>
    </div>
  );
}

export default AllWodsList;