import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { addTagToWod, completeWod, getWholeWod, removeTagFromWod, saveWodResult } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { useLocation, useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import { subPartListRenderer, linkedSubPartListRenderer } from "../smartfit_common/wodutil/wodutil";
import AddTags from "../tags/AddTags";
import RemoveTags from "../tags/RemoveTags";
import { Button } from 'flowbite-react'


function WodPartDetail () {
  const [tagList, setTagList] = useState([]);
  const [state, dispatch] = useContext(UserContext);
  const location = useLocation();
  const { id } = useParams();
  console.log(location);

  const [wod, setWod] = useState(location.state? location.state.wod: {id: id, description: "", name: "", result: ""});
  
  const [wodResultText, setWodResultText] = useState(wod.result);
  const [isCompleted, setCompleted] = useState(wod.is_completed);
  //const myWodPartList = subPartListRenderer(wod);
  const [myWodPartList, setMyWodPartList] = useState([]);
  console.log(wod);
  useEffect(() => {
    if (location.state) {
      //setMyWodPartList(subPartListRenderer(wod.child));
      setMyWodPartList(linkedSubPartListRenderer(wod.child));
      return;
    }
    getWholeWod(state, dispatch, undefined, id).then((result) =>{
      console.log("result.child" + id);

      setWod(result);
      //setMyWodPartList(subPartListRenderer(result.child));
      setMyWodPartList(linkedSubPartListRenderer(result.child));

    }).catch(e => console.log(e.message));
  }, [state, dispatch]);
  
  const listItems = myWodPartList.map(wod => {
    console.log(wod);
    return (
      <li key={wod.id.toString()}>
        {wod.exercises !== undefined && (wod.exercises.map(e => e))}
        {wod.exercises === undefined &&(wod.text)}

      </li>
    );
  });

  const refreshTagList = () => {
    getWholeWod(state, dispatch, undefined, wod.id).then((result) =>{
      //setMyWod(wodPartListRenderer(result));
      console.log(result);
      setTagList(result.tags);
      //console.log(myWodList[2]);
    });
  }

  return (
    <div>
      <h1>{wod.name + " " + wod.description}</h1>
      <h4>{isCompleted && ("(complete)") || ("(not completed)")}</h4>
      <ul>
        {listItems}  
      </ul>
      <textarea
        value={wodResultText}
        onChange={(e) => {
          setWodResultText(e.target.value);
        }}
        rows={5}
        cols={60}
      />
      <br/>
      <Button
        onClick={() => {
          saveWodResult(wod.id, wodResultText, state, dispatch);
        }}
      >
        save
      </Button>
      <Button
        onClick={() => {
          completeWod(wod, state, dispatch)
            .then(() => setCompleted(!isCompleted))
            .catch(e => console.log(e.message));
        }}
      >
        {!isCompleted && ("complete")}
        {isCompleted && ("UNcomplete")}
      </Button>
      <AddTags
        relatedId={wod.id} 
        refreshTagList={refreshTagList} 
        addTagCallback={addTagToWod}
      />
      <RemoveTags
        relatedId={wod.id}
        refreshTagList={refreshTagList}
        tagList={tagList}
        removeTagCallback={removeTagFromWod}
      />
    </div>
  );
}
export default WodPartDetail;