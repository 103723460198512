import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { saveMovement } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { Button, TextInput, Label } from 'flowbite-react'

function EnterMovement (props) {
  const [state, dispatch] = useContext(UserContext);
  const [movement, setMovement] = useState("");

  const handleSave = () => {
    saveMovement(state, dispatch, movement).then(() => {
      console.log("movement saved " + movement);
      props.update();
      setMovement("");
    }).catch((error) => {
      console.log(error.message);
    });
  }
  
  return (
    <div className='flex flex-row space-x-2 items-end '>
      <div>
        <Label value={"New Movement"}/>
        <TextInput 
          type="text" 
          value={movement}
          onChange={e => setMovement(e.target.value)}
        />
      </div>
      <Button
        onClick={handleSave}
      >
        save
      </Button>
    </div>
  );
}
export default EnterMovement;