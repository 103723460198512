import React, { useState, useContext, useEffect } from "react";
import { isWodAdmin } from "../usercontext/UserContext";
import UserContext from '../usercontext/UserContext';
import { getAllTags } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import EnterTag from './EnterTag';
import UpdateTag from './UpdateTag';
import {Pagination, Table, Label, TextInput} from 'flowbite-react'

function Tags () {
  const [state, dispatch] = useContext(UserContext);
  const [tagList, setTagList] = useState({tags: []});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState("");
  const isAdmin = isWodAdmin(state);
  useEffect(() => {
    getAllTags(state, dispatch, undefined, page, filter).then(result => {
      setTagList(result);
      const calcMaxPage = () => {
        if (result.count <= result.tags.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.tags.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [state, dispatch, page, filter]);

  const update = () => {
    getAllTags(state, dispatch, undefined, page, filter).then(result => {
      setTagList(result);
      const calcMaxPage = () => {
        if (result.count <= result.tags.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.tags.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }
  
  const listTableItems = tagList.tags.map((tag, i) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={tag.id.toString()}>
        
        
          <UpdateTag 
            update={update} 
            tagId={tag.id} 
            tagName={tag.name}
            hideName={false}
            hideButtons={true}
          />
        </Table.Cell>
        <Table.Cell>
          <p>
            {tag.text !== "no description" && tag.text}
          </p>
        </Table.Cell>
        <Table.Cell>
          <div className="flex justify-end">
            {isAdmin &&
              <UpdateTag 
              update={update} 
              tagId={tag.id} 
              tagName={tag.name}
              hideName={true}
              hideButtons={false}
              />
            }
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });


  const onPageChange = (p) => {
    setPage(p);
  }   

  return (
    <div>
      <div className="flex justify-between">
        <div >
          <Label
            value="Filter:"
          />
        
          <TextInput
            type="text"
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
              setPage(1);
            }}
          />
        </div>
      <div className="flex flex-row justify-end">
        <EnterTag update={update}/>
      </div>
      </div>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
      <Table striped={true} hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Tag</Table.HeadCell>
          <Table.HeadCell>Text</Table.HeadCell>
          <Table.HeadCell>
            <div className="flex justify-end">
              Actions
            </div>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
        {listTableItems}
      </Table.Body>
    </Table>

      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
      
    </div>
  );
}
export default Tags;