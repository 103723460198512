import React, { useState, useContext, useEffect } from "react";
import { Button, Modal,  } from 'flowbite-react';

function ConfirmModal ({buttonText, confirmText, onConfirm}) {
  const [show, setShow] = useState(false);

  function close () {
    setShow(false);
  }

  return (
    <React.Fragment>
      <Button 
        onClick={() => setShow(!show)}
        size={"xs"}
      >
        {buttonText}
      </Button>
      <Modal
        show={show}
        size="md"
        popup={true}
        onClose={() => setShow(false)}
        >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {confirmText}
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => {
                  onConfirm(close);
                }}
                >
                Yes, I'm sure
              </Button>
              <Button
                color="gray"
                onClick={() => setShow(false)}
                >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
export default ConfirmModal;