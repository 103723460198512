import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { saveTag, deleteTag, addTagToMovement} from "../smartfit_common/apihelper/apihelper";
import { Button } from 'flowbite-react'

import { Link } from 'react-router-dom';

function AddTag ({realtedId, tagId, tagName, refreshList, addTagCallback}) {
  const [state, dispatch] = useContext(UserContext);
  const handleAdd = () => {

    addTagCallback(state, dispatch, undefined, realtedId, tagId).then(() => {
      refreshList();
    }).catch(error => console.log(error.message));
  }
  
  return (

          <Button onClick={handleAdd} size="xs">
              add
          </Button>

  );
}
export default AddTag;