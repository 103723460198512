import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getWholeWod, addTagToWod, removeTagFromWod, getAllTags 
} from "../smartfit_common/apihelper/apihelper";

import RemoveTag from "../tags/RemoveTag";
import UpdateTag from "./UpdateTag";
import {Pagination, Table, Label, TextInput} from 'flowbite-react'


function RemoveTags ({relatedId, refreshTagList, tagList, removeTagCallback}) {
  //const [tagList, setTagList] = useState([]);
/*
  useEffect(() => {
    refreshTagList();
  }, [state, dispatch]);
*/
  
  const listTableItems = tagList.map((tag, i) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={tag.id.toString()}>
          
          <UpdateTag 
            update={refreshTagList} 
            tagId={tag.id} 
            tagName={tag.name}
            hideName={false}
            hideButtons={true}
            text={tag.text}
          />
        </Table.Cell>

        <Table.Cell>
        <div className="flex justify-end">
          <RemoveTag 
            relatedId={relatedId} 
            tagId={tag.id} 
            tagName={tag.name} 
            refreshList={refreshTagList}
            removeTagCallback={removeTagCallback}
          />
        </div>
        </Table.Cell>
        
      </Table.Row>
    );
  });

  return (
    <div>
      <Table striped={true} hoverable={true}>
        <Table.Head> 
        <Table.HeadCell colSpan={2}><div className="flex  justify-center">TAGs</div></Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
        {listTableItems}
      </Table.Body>
    </Table>
    </div>
  );
}
export default RemoveTags;