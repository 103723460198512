import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { saveTag, deleteTag, updateTagName} from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import DeleteTag from './DeleteTag';
import { Link } from 'react-router-dom';
import { Button, Badge, Tooltip } from 'flowbite-react';

function UpdateTag (props) {
  const [state, dispatch] = useContext(UserContext);
  const [clicked, setClicked] = useState(false);
  const [tag, setTag] = useState(props.tagName);

  const handleUpdate = () => {
    setClicked(false);
    updateTagName(state, dispatch, undefined, props.tagId, tag).then(() => {
      props.update();
    }).catch(error => console.log(error.message));
  }
  
  const handleClick = () => {
    setClicked(true);
  }

  const handleCancel = () => {
    setClicked(false);
    setTag(props.tagName);
  }

  return (
    <div class="flex flex-row space-x-2 ...">
      {!clicked &&
        <div class="flex flex-row space-x-2 ...">
          {!props.hideName && 
            <Tooltip
              content={props.text !== "no description"? props.text: ""}
            >
              <Link to={"/tags/"+props.tagId}>
                <Badge>
                  {props.tagName}
                </Badge>
              </Link>
            </Tooltip>
          } 
          {!props.hideButtons && 
            <Button onClick={handleClick}>
              rename
            </Button>
          }
          {!props.hideButtons && 
            <DeleteTag 
              update={props.update} 
              tagId={props.tagId}
            />
          }
        </div>
      }
      {clicked && 
        <>
          <input 
            type="text"
            placeholder={props.tagName} 
            value={tag}
            onChange={e => setTag(e.target.value)}
          />
          <Button onClick={handleUpdate}>
            save
          </Button>
          <Button onClick={handleCancel}>
            cancel
          </Button>
        </>
      }
    </div>  
  );
}
export default UpdateTag;