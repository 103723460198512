import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { apiCallGet, getAllMovements } from "../smartfit_common/apihelper/apihelper";
import { Button, Badge } from 'flowbite-react'
import {Pagination, Table, Label, TextInput} from 'flowbite-react'
import UpdateMovement from "../movements/UpdateMovement";

function StaticSelector ({selectedMovements, settingsUpdate, itemKey, index, allowedValues}) {
  //const [state, dispatch] = useContext(UserContext);
  //const [movementList, setMovementList] = useState(allowedValues);
  const [showTable, setShowTable] = useState(false);



  function addMovement (movementName) {
    console.log(index);
    if (index !== undefined) {
      selectedMovements.push(movementName);
      settingsUpdate(selectedMovements, itemKey, index);
      return;
    }
    selectedMovements.push(movementName);
    settingsUpdate(selectedMovements, itemKey);   
  }

  const listTableItems = allowedValues.map((movement, i) => {
    if (selectedMovements.includes(movement)) 
      return undefined;
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800"  key={i}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" >
         
        <Badge>
          {movement}
        </Badge>
        </Table.Cell>

        <Table.Cell>
          <div className="flex justify-end">
          <Button
            size={"xs"}
            onClick={
              () => addMovement(movement)
            }
          >
            add
          </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });
   
  
  return (<>
    {!showTable && 
      <Button
        onClick={() => setShowTable(true)}
        size={"xs"}
      >
        add
      </Button> 
    }
    <div className="flex flex-col space-y-3">

    {showTable && 
      <Button
        onClick={() => setShowTable(false)}
        >
        Done
      </Button> 
    }
    {showTable && 
      <div>
        
        
        <Table striped={true} hoverable={true}>
          <Table.Head>
            <Table.HeadCell>Tag</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
          {listTableItems}
        </Table.Body>
      </Table>
        
      </div>
    }
    </div>
  </>
  );
}
export default StaticSelector;