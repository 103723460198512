import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { updateMovementName } from "../smartfit_common/apihelper/apihelper";
import { Link } from 'react-router-dom';
import { Button, TextInput } from 'flowbite-react'
import DeleteMovement from './DeleteMovement';

function UpdateMovement (props) {
  const [state, dispatch] = useContext(UserContext);
  const [clicked, setClicked] = useState(false);
  const [movement, setMovement] = useState(props.moveName);

  useEffect(()=> {
    setMovement(props.moveName);
  }, [props.moveName]);

  const handleUpdate = () => {
    setClicked(false);
    updateMovementName(state, dispatch, undefined, props.moveId, 
      movement).then(() => {
      props.update();
    }).catch(error => console.log(error.message));
  }
  
  const handleClick = () => {
    setClicked(true);
  }

  const handleCancel = () => {
    setClicked(false);
    setMovement(props.moveName);
  }

  return (
    <div class="flex flex-row space-x-2 ...">
      {
        !clicked &&  (<>
          {!props.hideName && 
            <Link to={"/movements/"+props.moveId} >
              <p class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                {props.moveName}
              </p>
            </Link>}{" "}
          {
            !props.hideButtons && 
            <div>
              <Button 
                onClick={handleClick}
                size={"xs"}
              >
                rename
              </Button>
            </div>
          }          
          {
            !props.hideButtons && <div><DeleteMovement update={props.update} moveId={props.moveId}/></div>
          }  
        </>)
      }
      {
        clicked && (<>
          <div class="flex flex-col space-y-1">
            <TextInput 
              type="text" 
              value={movement}
              onChange={e => setMovement(e.target.value)}
            />
            <div class="flex flex-row space-x-2 ...">
              <Button 
                onClick={handleUpdate}
                size={"xs"}
              >
                save
              </Button>
            
              <Button 
                onClick={handleCancel}
                size={"xs"}
              >
                cancel
              </Button>
            </div>
          </div>
        </>)
      }
    </div>
  );
}
export default UpdateMovement;
<div class="flex flex-row ...">
<div>01</div>
<div>02</div>
</div>