import React, { useState, useContext, useEffect } from "react";
import UserContext, {isAdminuser, isWodAdmin, } from '../usercontext/UserContext';
import { getAllMovements, mergeMovements } from "../smartfit_common/apihelper/apihelper";
import EnterMovement from './EnterMovement';
import UpdateMovement from './UpdateMovement';
import UpdateTag from '../tags/UpdateTag';
import { Button, Tooltip } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { Badge, Pagination, Table, Checkbox, TextInput, Label } from 'flowbite-react';

function Movements () {
  const [state, dispatch] = useContext(UserContext);
  const [movementList, setMovementList] = useState({wods: []});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [mergeOn, setMergeOn] = useState(false);
  const [numberSelected, setNumberSelected] = useState(0);
  const [boxes, setBoxes] = useState([]);

  const wodAdmin = isWodAdmin(state);
  const isAdmin = isAdminuser(state);
  
  useEffect(() => {
    getAllMovements(state, dispatch, undefined, page, filter).then(result => {
      setMovementList(result);
      setBoxes(new Array(result.wods.length).fill(false));
      const calcMaxPage = () => {
        if (result.count <= result.wods.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.wods.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [state, dispatch, page, filter]);

  const update = () => {
    getAllMovements(state, dispatch, undefined, page, filter).then(result => {
      setMovementList(result);
      setBoxes(new Array(result.wods.length).fill(false))
      const calcMaxPage = () => {
        if (result.count <= result.wods.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.wods.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }

  const getSelectedBoxes = () => {
    let selected = 0;
    for (let b of boxes) {
      if (b)
        selected ++;
    }
    return selected;
  }


  const listTableItems = movementList.wods.map((movement, i) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        {mergeOn && <Table.Cell className="!p-4">
          <Checkbox />
        </Table.Cell>}
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={movement.id.toString()}>
          {mergeOn && (getSelectedBoxes() < 2 || boxes[i])&&(
          <input 
            type="checkbox"
            onChange={() => {
              if (getSelectedBoxes() > 1 && !boxes[i]) return
              boxes[i] = !boxes[i];
              setBoxes([...boxes])
              console.log(getSelectedBoxes());
              console.log(boxes);
            }}
            checked={boxes[i]}
          />)}
          <UpdateMovement 
            update={update} 
            moveId={movement.id} 
            moveName={movement.name}
            hideName={false}
            hideButtons={true}
          />
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-row space-x-2">

          {movement.tags.map((tag, i, a ) => {
            return <Link to={"/tags/"+tag.id} key={tag.id}>
                      <Tooltip
                        content={tag.text}
                      >
                        <Badge>
                          {tag.name}
                        </Badge>
                      </Tooltip>
                    </Link>
          })}
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="flex justify-end">
            {wodAdmin &&
              <UpdateMovement 
                  update={update} 
                  moveId={movement.id} 
                  moveName={movement.name}
                  hideName={true}
                  hideButtons={false}
                />
            }   
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });

  const onPageChange = (e) => {
    console.log(e)
    setPage(e);
  }

  const mergeHandler = () => {
    setMergeOn(!mergeOn);
    const movements = [];
    boxes.forEach((selected, index) => {
      if (selected)
        movements.push(index);
    });
    console.log(movements);
    const move1 = movementList.wods[movements[0]], move2 = movementList.wods[movements[1]];
    mergeMovements(state, dispatch, move1.id, move2.id).then(res => {
      console.log(res);
      update();
    }).catch(error => {
      console.log(error.message)
    });
  }

  return (
    <div>
      <div class="flex justify-between">
        <div>
          <Label value={"Filter:"}/>
          <TextInput 
            type="text"
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
              setPage(1);
            }}
          />
        </div>
        <div className="flex flex-row justify-end">
          <EnterMovement update={update}/>
        </div>
        {!mergeOn && isAdmin && (<Button
          onClick={() => setMergeOn(!mergeOn)}
          >
          merge movements
        </Button>)}
        {mergeOn && (<>Select two <Button
          onClick={mergeHandler}
          >
          merge now
        </Button>
        <Button onClick={() => setMergeOn(!mergeOn)}>
          cancel
        </Button></>)}
      </div>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
      <Table striped={true} hoverable={true}>
        <Table.Head>
          {mergeOn && <Table.HeadCell className="!p-4">
            select to merge
          </Table.HeadCell>}
          <Table.HeadCell>Movement</Table.HeadCell>
          <Table.HeadCell>Tags</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {listTableItems}
        </Table.Body>
      </Table>
      
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />

      
    </div>
  );
}
export default Movements;