import React, { useState, useContext, useEffect, useCallback } from "react";
import UserContext from '../usercontext/UserContext';
import { getUserList } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import {Link} from 'react-router-dom';
import DeactivateUser from "./DeactivateUser";
import { saveTag, deleteUser} from "../smartfit_common/apihelper/apihelper";
import {Button} from 'flowbite-react'
import { Table } from "flowbite-react";

function UsersList () {
  const [state, dispatch] = useContext(UserContext);
  const [wodList, setWodlist] = useState({results: []});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [hideActive, setHideActive] = useState(false);
  const [hideInActive, setHideInActive] = useState(true);

  const update = useCallback(() => {
    let isActive = undefined;
    if (hideInActive && !hideActive)
      isActive = true;
    else if (!hideInActive && hideActive)
      isActive = false;
    getUserList(state, dispatch, undefined, isActive, page).then(result => {
      setWodlist(result);
      console.log(result);
      const calcMaxPage = () => {
        if (result.count <= result.results.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.results.length);
      }
      setMaxPage(calcMaxPage);
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [dispatch, hideActive, hideInActive, page, state]);

  useEffect(() => {
    update();
  },[update ]);

  const tableListItems = wodList.results.map((user, i) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>

      <Table.Cell key={user.id.toString()}>
        <Link to={""+user.id}>{user.username}</Link> 
      </Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{user.is_active? "Active": "not"}</Table.Cell>
      <Table.Cell>
        {user.is_coach && !user.is_coach? "Coach": ""}
        {user.is_athlete && !user.is_coach? "Athlete": ""}
        {user.is_athlete && user.is_coach? "Coach & Athlete": ""}
      </Table.Cell>
      <Table.Cell align="center">{user.wodparts.length}</Table.Cell>
      <Table.Cell align="center">
        <DeactivateUser id={user.id} update={update} isActive={user.is_active}/>
      </Table.Cell>
      </Table.Row>
    );
  });
  
  const loadNext = () => {
    if (wodList.next === null)
      return;
    if (wodList.results.length === 0)
      return;
    if (page >= maxPage) {
      setPage(maxPage);
      return;
    }
    setPage(page + 1);
  }

  const loadPrev = () => {
    if (page <= 1) return;
    setPage(page - 1);
  }   
  return (
    <div>
      <label>hide inactive</label>
      <input
        type="checkbox"
        onChange={() => {
          setHideInActive(!hideInActive);
          setHideActive(hideInActive);
        }}
        checked={hideInActive}
      />
      <br/>
       <label>hide active</label>
      <input
        type="checkbox"
        onChange={() => {
          setHideActive(!hideActive);
          setHideInActive(hideActive);
        }}
        checked={hideActive}
      />
      <Table  striped={true} hoverable={true}>
      <Table.Head>
          <Table.HeadCell>Username</Table.HeadCell>
          <Table.HeadCell>Email</Table.HeadCell>
          <Table.HeadCell>is Active</Table.HeadCell>
          <Table.HeadCell>Role</Table.HeadCell>
          <Table.HeadCell>Number of Wods</Table.HeadCell>
          </Table.Head>
        <Table.Body>
          {tableListItems}
        </Table.Body>
      </Table>
      <Button
        onClick={loadNext}
      >
        next
      </Button>
      <Button
        onClick={loadPrev}
      >
        previous
      </Button>
      <br/>
      Page: {page} of {maxPage}
      <br/>
    </div>
  );
}
export default UsersList;