import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { apiCallGet, getAllMovements } from "../smartfit_common/apihelper/apihelper";
import { Button } from 'flowbite-react'
import {Pagination, Table, Label, TextInput} from 'flowbite-react'
import UpdateMovement from "../movements/UpdateMovement";

function FetchSelector ({selectedMovements, settingsUpdate, itemKey, index, url}) {
  const [state, dispatch] = useContext(UserContext);
  const [movementList, setMovementList] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (!showTable)
      return;
    //getAllMovements(state, dispatch, undefined, page, filter).then(result => {
    apiCallGet(state, dispatch, undefined, url, 
    {
      ordering: "name",
      page: page, 
      search: filter
    }).then(result => {
      setMovementList(result.results);
      const calcMaxPage = () => {
        if (result.count <= result.results.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.results.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [dispatch, filter, page, showTable, state, url]);

  const update = () => {
    getAllMovements(state, dispatch, undefined, page, filter).then(result => {
      setMovementList(result);
      const calcMaxPage = () => {
        if (result.count <= result.wods.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.wods.length);
      }
      setMaxPage(calcMaxPage);
      console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }

  function addMovement (movementName) {
    console.log(index);
    if (index !== undefined) {
      selectedMovements.push(movementName);
      settingsUpdate(selectedMovements, itemKey, index);
      return;
    }
    selectedMovements.push(movementName);
    settingsUpdate(selectedMovements, itemKey);   
  }

  const listTableItems = movementList.map((movement, i) => {
    if (selectedMovements.includes(movement.name)) 
      return undefined;
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={movement.id.toString()}>
         
        <UpdateMovement 
            update={update} 
            moveId={movement.id} 
            moveName={movement.name}
            hideName={false}
            hideButtons={true}
          />
        </Table.Cell>

        <Table.Cell>
          <div className="flex justify-end">
          <Button
            size={"xs"}
            onClick={
              () => addMovement(movement.name)
            }
          >
            add
          </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });
  

  const onPageChange = (p) => {
    setPage(p);
  }   
  
  return (<>
    {!showTable && 
      <Button
        onClick={() => setShowTable(true)}
        size={"xs"}
      >
        add
      </Button> 
    }
    <div className="flex flex-col space-y-3">

    {showTable && 
      <Button
        onClick={() => setShowTable(false)}
        >
        Done
      </Button> 
    }
    {showTable && 
      <div>
        <div>
          <div className="mb-2 block">
            <Label
              value="Filter:"
              />
          </div>
          <TextInput
            type="text"
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
              setPage(1);
            }}
            />
        </div>
        <Pagination
          currentPage={page}
          onPageChange={onPageChange}
          showIcons={true}
          totalPages={maxPage}
          />
        <Table striped={true} hoverable={true}>
          <Table.Head>
            <Table.HeadCell>Tag</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
          {listTableItems}
        </Table.Body>
      </Table>
        <Pagination
          currentPage={page}
          onPageChange={onPageChange}
          showIcons={true}
          totalPages={maxPage}
          />
      </div>
    }
    </div>
  </>
  );
}
export default FetchSelector;