import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../navbar/NavBar";
import { createUser } from "../smartfit_common/authmanager/AuthManager"
import { Button } from 'flowbite-react'

function RegisterUser () {
  
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isRegistered, setRegistered] = useState(false);
  const [gotErrorMsg, setErrorMsg] = useState(false);
  const [errorData, setErrorData] = useState({username: undefined, password: undefined});
  
  useEffect(() => {
    
  }, []);
  
  const handleRegister = () => {
    if (password !== password2) {
      setErrorMsg(true);
      const data = { password: ["passwords did not match"]}
      setErrorData(data)
      return;
    }
    createUser({
      username: username, 
      password: password,
      email: email
    }).then((res) => {
      //navigate("/login");
      console.log(res);
      if(res.status >= 200 && res.status <= 299)
        setRegistered(true);
      else {
        setErrorMsg(true);
        setErrorData(res.data);
      }

    }).catch((e) => {
      console.log(e.message);
    });
  }

  return <div>{!isRegistered&&<div>  
    <h1>Register</h1>
    Email: <input 
      type="text" 
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
    <br/>
    Username: <input 
      type="text" 
      value={username}
      onChange={e => setUsername(e.target.value)}
    />
    <br/>
    Password: <input 
      type="password"
      value={password}
      onChange={e => setPassword(e.target.value)}
    />
    <br/>
    Confirm Password: <input 
      type="password" 
      value={password2}
      onChange={e => setPassword2(e.target.value)}
    />
    <br/>
    <Button
      onClick={handleRegister}
    >
      register
    </Button>
    <NavBar/>
  </div>}
  {isRegistered&&<CheckYourMail/>}
  {gotErrorMsg&&<ShowError data={errorData}/>}
  </div>
}
export default RegisterUser;

function CheckYourMail() {
  return <div>
    <h2>Check your EMail</h2>
  </div>
}

function ShowError({data}) {
  const text = getErrorText(data.password) + getErrorText(data.username)
  + getErrorText(data.email);
  return <div>
    <h2>Check Error MSG</h2>
    <h4>{text}</h4>
  </div>
}

const getErrorText = (textList) => {
  if (textList === undefined || textList.lenth === 0)
    return "";
  return textList.join(" ");
}