import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { activateUser } from "../smartfit_common/authmanager/AuthManager"

function ActivateUser () {
  
  const { uid, token } = useParams();
  const [activated, setActivated] = useState("not activated");
  
  useEffect(() => {
    activateUser(uid, token).then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        setActivated("activated!")
      } else  {
        console.log(res);
        setActivated("what " + res.status);
      }
    }).catch((e) => {
      console.log(e.message);
    });
  }, []);

  return <div>  
    <h1>Activate</h1>
    status: {activated}
    <br/>
    <Link to="/login">Login</Link>
  </div>
}
export default ActivateUser;