import axios from "axios";
import qs from 'qs';
import { getAxiosConfig, getAxiosConfigfromToken } from "../apihelper/apihelper";

axios.defaults.baseURL =  process.env.REACT_APP_SERVER_URL != undefined? 
  process.env.REACT_APP_SERVER_URL: 'http://localhost:8000';
//axios.defaults.baseURL =  'https://smartfit-django-dtotc.ondigitalocean.app/';

//axios.defaults.baseURL =  'http://localhost:8000';
console.log(process.env
  );
const storeString = async (value) => {
  try {
    localStorage.setItem('@storage_Key', value);
  } catch (e) {
    console.log(e.message);
    return e;
  }
}
export const storeObject = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem('@storage_Key', jsonValue);
    console.log("ojbject stored");
  } catch (e) {
    console.log(e.message);
    return e;
  }
}

const readString = async () => {
  try {
    const value = await localStorage.getItem('@storage_Key');
    if(value !== null) {
      return value;
    }
  } catch(e) {
    console.log(e.message);
    return e;
  }
}

export const readObject = async () => {
  try {
    const jsonValue = localStorage.getItem('@storage_Key');
    console.log("data read from local storage: "+ jsonValue);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch(e) {
    console.log(e.message);
    return e;
  }
}

export const getToken = async (uname, pwd) => {
    const data = {username: uname, password: pwd};
    try {
      const response = await axios.post('/auth/jwt/create/', data);
      if (response.data) {
        storeObject(response.data);
        return response.data;         
      }
    } catch (error) {
        console.log("AuthManager.getToken");
        console.log(error.name + " " + error.message);
        return Promise.reject(error);
    }
}

export async function verifyToken(token){
  if(token === undefined){
      //token = store.getState().user.accessToken;
    return false;
  }
  const data = {token: token};
  try {
    const response = await axios.post('/auth/jwt/verify/', data);
    console.log(response.status);
    if (response.status === 200) {
        console.log("verified!")
        return true;
    }else{
        console.log("expired!");
        return false;
    }
  } catch (error) {
    console.log(error.message)
    return false;
  }
}

export const getUser = async (state, id, token) => {
  const sessionUrl = '/users/'+id+'/';
  const config = (token? getAxiosConfigfromToken(token): getAxiosConfig(state));

  //console.log("getUser: " + sessionUrl);
  //console.log(getAxiosConfig());
  try {
    const response = await axios.get(sessionUrl, config);
    //console.log("get User", response.data.username);
    return response.data;
  } catch (error) {
    console.log("get user - " + error.message);
    return error;
  }
}

export const getOwnUserInfo = async (state, token) => {
  const sessionUrl = '/auth/users/me/';
  //console.log("getUser: " + sessionUrl);
  //console.log(getAxiosConfig());
  const config = (token? getAxiosConfigfromToken(token): getAxiosConfig(state));
  try {
    console.log(config);
    const response = await axios.get(sessionUrl, config);
    //console.log("get User", response.data.username);
    return response.data;
  } catch (error) {
    console.log("get user - " + error.message);
    return error;
  }
}

export async function getUserInfo(state, userId, token){
//TODO serializer returns all info about user, including all related wodparts, make new serializer without wodparts
  let id = -1; 
  const config = (token? getAxiosConfigfromToken(token): getAxiosConfig(state));
  try {
    if (userId)
      id = userId;
    else {
      const response = await axios.get('/auth/users/me/', config);
      //console.log("/auth/users/me/");
      //console.log(response.data);  
      id = response.data.id;
    }
    const userResponse = await axios.get('/users/'+id+'/', config);
    return { 
      id: userResponse.data.id,
      //url: axios.defaults.baseURL + '/users/'+obj.id+'/',
      url: userResponse.data.url,
      email: userResponse.data.email,
      isAthlete: userResponse.data.is_athlete,
      isCoach: userResponse.data.is_coach,
      userName: userResponse.data.username,
      firstName: userResponse.data.first_name,
      lastName: userResponse.data.last_name,
    };

    //console.log(JSON.stringify(response.data));
  } catch (error) {
    console.log("Error!" + error.message)
    return false;
  }
  //console.log("get user info");
  //console.log(obj);
}

export async function refreshToken(refresh_token){
    if(refresh_token === undefined){
        //refresh_token = store.getState().user.refreshToken;
        return new Error("refresh token undefined");
    }
    const data = {refresh: refresh_token};
 
    try {
      const response = await axios.post('/auth/jwt/refresh/', data);

      console.log(response.data);
      return response.data.access;
    } catch (error) {
      console.log("refreshToken! " + error.message);
      throw error;
    } 
}

export async function createUser(values) {
    const session_url = '/auth/users/';
    const userData = {
      username:values.username, 
      password:values.password,
      email: values.email
    };
    console.log(userData);

    try{
        //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        const res = await axios.post(session_url, userData, {'Content-Type':'application/x-www-form-urlencoded'});
        //this.setState({wods: res.data.results, count: res.data.count});
        return res;
    }catch(error){
        console.log("AuthManager.createUser");
        console.log(error.name + " " + error.message);
        if (error.response.status === 401) {
            return error.response;
        }
        return error.response;
    }
}

export async function activateUser(uid, token) {
  const sessionUrl = '/auth/users/activation/';
  const userData = {
    uid: uid, 
    token: token
  };
  try {
      //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      const res = await axios.post(sessionUrl, userData, {'Content-Type':'application/x-www-form-urlencoded'});
      //this.setState({wods: res.data.results, count: res.data.count});
      return res;
  } catch (error){
      console.log("AuthManager.activateUser");
      console.log( error.message);
      return error.response.status;
  }
}

export async function confirmPasswordReset(uid, token, newPassword) {
  const sessionUrl = '/auth/users/reset_password_confirm/';
  const userData = {
    uid: uid, 
    token: token,
    new_password: newPassword 
  };
  try {
      //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      const res = await axios.post(sessionUrl, userData, {'Content-Type':'application/x-www-form-urlencoded'});
      //this.setState({wods: res.data.results, count: res.data.count});
      return res;
  } catch (error){
    console.log("AuthManager.confirmPasswordReset");
    console.log(error.name + " " + error.message);
    return error.response;
  }
}

export async function requestPasswordReset(email) {
  const sessionUrl = "/auth/users/reset_password/"
  const userData = {
    email: email
  };
  try {
      //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      const res = await axios.post(sessionUrl, userData, {'Content-Type':'application/x-www-form-urlencoded'});
      //this.setState({wods: res.data.results, count: res.data.count});
      return res;
  } catch (error){
    console.log("AuthManager.rquestPasswordReset");
    console.log(error.name + " " + error.message);
    return error.response;
  }
}

export async function getUsernameByEmail(email) {
  const sessionUrl = "/getusername/";
  const data = qs.stringify({
    'email': email 
  });
  const config = {
    method: 'post',
    url: sessionUrl,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
  };
//axios(options);

  try {
      //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      //const res = await axios.get(sessionUrl, {params: params, 'Content-Type':'application/x-www-form-urlencoded'});
      const res = await axios(config);
      //this.setState({wods: res.data.results, count: res.data.count});
      return res;
  } catch (error){
    console.log("AuthManager.getUsernameByEmail");
    console.log(error.name + " " + error.message);
    return error.response;
  }
}

