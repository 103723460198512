import React, {useEffect, useState, useContext} from "react";
import {getMyWodList} from "../smartfit_common/apihelper/apihelper";
import { rootWodListRenderer } from '../smartfit_common/wodutil/wodutil';
import UserContext from '../usercontext/UserContext';
import NavBar from "../navbar/NavBar";
import { useParams, useLocation, Link } from "react-router-dom";
import WodPreview from "../woddetail/WodPreview";

const WodPartList = () => {
  const [state, dispatch] = useContext(UserContext);
  const [myWodList, setMyWodList] = useState({wods: []});

  const { owner, completed } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(location.state?.page? location.state.page: 1);
  const [maxPage, setMaxPage] = useState(1);
  console.log(location);

  useEffect(() => {
    //getMyWodList(state, dispatch, undefined, owner, completed, 
    //  location.state?.movement, location.state?.tag, page).then((result) =>{
      getMyWodList(state, dispatch, undefined, {
        owner: owner, 
        is_completed: completed,
        movement: location.state?.movement, 
        tag: location.state?.tag,
        page: page,
        ordering: "creation_date"
      }).then((result) =>{  
        setMyWodList({...result, wods: rootWodListRenderer(result.wods)});

      const calcMaxPage = () => {
        if (result.count <= result.wods.length) 
            return 1;
        if (result.next === null)
            return page;
        return Math.ceil(result.count / result.wods.length);
      }
      setMaxPage(calcMaxPage);
    }).catch(error => console.log(error.message));
  }, [owner, completed, state, dispatch, page, location]);

  console.log(myWodList);

  const listItems = myWodList.wods.map(wod => {
    return (
      <li key={wod.id.toString()}>
        <h5>
          <Link to={"/woddetail/"+(wod.wod.parent_id || wod.id)} state={{wod: wod}}>
            {wod.name + " " + wod.description + " " + formatDate(wod.wod.creation_date)}
          </Link>
        </h5>
        <WodPreview wod={wod.wod}/>
      </li>
    );
  });
  
  const loadNext = () => {
    if (myWodList.next === null)
      return;
    if (myWodList.wods.length === 0)
      return;
    if (page >= maxPage) {
      setPage(maxPage);
      return;
    }
    setPage(page + 1);
  }

  const loadPrev = () => {
    if (page <= 1) return;
    setPage(page - 1);
  }

  return (
    <div>
      {!owner && (<h1>Wods by {location.state?.movementName || location.state?.tagName}</h1>)}
      {owner && (<Link to={"/users/"+owner}><h1>{location.state.userName}</h1></Link>)}
      
      {owner && (
        <><Link to={"/wodlist/"+owner} state={{userName: location.state?.userName}}>
          {completed === undefined && (<b>All</b>)}
          {completed !== undefined && (<>All</>)}{" | "}
        </Link>
        <Link to={"/wodlist/"+owner+"/true"} state={{userName: location.state?.userName}}>
          {completed === "true" && (<b>Completed</b>)}
          {completed !== "true" && (<>Completed</>)}{" | "}
        </Link>
        <Link to={"/wodlist/"+owner+"/false"} state={{userName: location.state?.userName}}>
          {completed === "false" && (<b>New</b>)}
          {completed !== "false" &&(<>New</>)}
        </Link></>
      )}
      
      <ul>
        {listItems}  
      </ul>
      <button
        onClick={loadNext}
      >
        next
      </button>
      <button
        onClick={loadPrev}
      >
        previous
      </button>
      <br/>
      Page: {page} of {maxPage}
      <br/>
    </div>
  );
}
//2022-07-20T15:37:28.343945Z
function formatDate(dateTimeStr) {
  return dateTimeStr.split("T")[0];
}
export default WodPartList;
