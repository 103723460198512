import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { confirmPasswordReset } from "../smartfit_common/authmanager/AuthManager"
import { Button } from 'flowbite-react'

function ResetPassword () {
  
  const { uid, token } = useParams();
  //const [activated, setActivated] = useState("not activated");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isRegistered, setRegistered] = useState(false);
  const [gotErrorMsg, setErrorMsg] = useState(false);
  const [errorData, setErrorData] = useState({username: undefined, password: undefined});

  const callReset = () => {
    confirmPasswordReset(uid, token, password).then((res) => {
      console.log(res);
      if(res.status >= 200 && res.status <= 299)
        setRegistered(true);
      else {
        setErrorMsg(true);
        setErrorData(res.data);
      }
    }).catch((e) => {
      console.log(e.message);
    });
  }

  return <div>{!isRegistered&&<div>  
    <h1>Reset Password</h1>
    Password: <input 
      type="text" 
      value={password}
      onChange={e => setPassword(e.target.value)}
    />
    <br/>
    Confirm Password: <input 
      type="text" 
      value={password2}
      onChange={e => setPassword2(e.target.value)}
    />
    <br/>
    <Button
      onClick={callReset}
    >
      register
    </Button>
  </div>}
  {isRegistered&&<CheckYourMail/>}
  {gotErrorMsg&&<ShowError data={errorData}/>}
  </div>
}
export default ResetPassword;

function CheckYourMail() {
  return <div>
    <h2>Password has been changed</h2>
    <Link to="/login">Login</Link>
  </div>
}

function ShowError({data}) {
  console.log(data);
  const text = getErrorText(data.new_password) + getErrorText(data.username);
  return <div>
    <h2>Check Error MSG</h2>
    <h4>{text}</h4>
  </div>
}

const getErrorText = (textList) => {
  if (textList === undefined || textList.lenth === 0)
    return "";
  return textList.join(" ");
}