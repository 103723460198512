import React, { useState, useContext, useEffect } from "react";
import UserContext, { isAdminuser } from '../usercontext/UserContext';
import { 
  getWholeWod, addTagToWod, removeTagFromWod, deleteWod, getUserDetail, apiCallGet
} from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';

import { useLocation, useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import { 
  wodPartListRenderer, rootWodListRenderer, linkedSubPartListRenderer
} from "../smartfit_common/wodutil/wodutil";
import WodPreview from "./WodPreview";
import DeleteWod from "./DeleteWod";
import AddTags from "../tags/AddTags";
import RemoveTags from "../tags/RemoveTags";
import { Button, Textarea, Card } from 'flowbite-react'


function WodDetailModify () {
  const [state, dispatch] = useContext(UserContext);
  const [myWod, setMyWod] = useState([]);
  //const [tagList, setTagList] = useState([]);
  const [wodName, setWodName] = useState("");
  const [date, setDate] = useState("");
  const [ownerName, setOwnerName] = useState(undefined);
  const [ownerId, setOwnerId] = useState(undefined);
  const { id } = useParams();
  const location = useLocation();

  const isAdmin = isAdminuser(state);
  const direction = location.state.direction;
  const [filteredList, setFilteredList] = useState(location.state.list);
  const [filteredListIndex, setFilteredListIndex] = useState(location.state.index);
  const [nextRequest, setNextRequest] = useState(location.state.nextRequest);
  const [prevRequest, setPrevRequest] = useState(location.state.prevRequest);

  const [nextIndex, setNextIndex] = useState(location.state.index < filteredList.length - 1?
    location.state.index + 1: location.state.index);
  const [prevIndex, setPrevIndex] = useState(location.state.index > 0?
    location.state.index - 1: 0);
  const [nextWod, setNextWod] = useState(filteredList[nextIndex]);
  const [prevWod, setPrevWod] = useState(filteredList[prevIndex]);
  console.log(filteredList);
  console.log(filteredListIndex);
  console.log(nextIndex, prevIndex);
  console.log(id);
  useEffect(() => {
    getWholeWod(state, dispatch, undefined, id).then((result) =>{
      setMyWod(wodPartListRenderer(result.child));
      setWodName(result.name);
      console.log(result.creation_date);
      setDate((result.creation_date+"").substring(0, 10));
      console.log(result.child)
      //setTagList(result.tags);
      console.log(result);
      setOwnerId(result.owner_id);


      if (filteredListIndex === filteredList.length - 1 && nextRequest !== undefined && nextRequest !== null) {
        console.log("last wod in list"+ nextRequest);
        apiCallGet(state, dispatch, undefined, nextRequest).then(res => {
          console.log(res);
          const newList = filteredList.concat(rootWodListRenderer(res.results))
          setFilteredList(newList);
          const newNextIndex = filteredListIndex < newList.length - 1?
            filteredListIndex + 1: filteredListIndex;
          console.log(newList);
          setNextIndex(newNextIndex);
          setNextWod(newList[newNextIndex]);

          setNextRequest(res.next);
          //setPrevRequest(res.previous);
        }).catch(err => console.log(err.message));
      } else if (filteredListIndex === 0 && prevRequest !== undefined && prevRequest !== null) {
        console.log("first wod in list"+ prevRequest);
        apiCallGet(state, dispatch, undefined, prevRequest).then(res => {
          console.log(res);
          const prevList = rootWodListRenderer(res.results);
          const newList = prevList.concat(filteredList);
          setFilteredList(newList);
          const newPrevIndex = filteredListIndex > 0?
            prevList.length + filteredListIndex - 1: filteredListIndex;
          console.log(newList);
          setPrevIndex(newPrevIndex);
          setPrevWod(newList[newPrevIndex]);
          location.state.index = filteredListIndex + prevList.length
          setFilteredListIndex(filteredListIndex + prevList.length);
          //setNextRequest(res.next);
          setPrevRequest(res.previous);
        }).catch(err => console.log(err.message));
      } else {
        console.log("what is the index here", filteredListIndex, direction);
        const newNextIndex = filteredListIndex < filteredList.length - 1?
        filteredListIndex + 1: filteredListIndex;
        setNextIndex(newNextIndex);
        setNextWod(filteredList[newNextIndex]);
        const newPrevIndex = filteredListIndex > 0?
        filteredListIndex - 1: filteredListIndex;
        setPrevIndex(newPrevIndex);
        setPrevWod(filteredList[newPrevIndex]);
        setFilteredListIndex(location.state.index);
      }
      getUserDetail(state, dispatch, undefined, result.owner_id).then(res => {
        console.log(res);
        setOwnerName(res.username);  
      }).catch(err => console.log(err.message));
    }).catch(error => console.log(error.message));
  }, [state, dispatch, id, filteredList, filteredListIndex, nextRequest, 
    direction, prevRequest, location.state.index, location.state]);

  const update = () => {
    getWholeWod(state, dispatch, undefined, id).then((result) =>{
      setMyWod(wodPartListRenderer(result.child));
      setWodName(result.name);
      console.log(result.child)
      //setTagList(result.tags);
    });
  }

  const requestNextList = () => {
    console.log("requestNextList " + nextRequest);
  }

  const refreshTagList = () => {
    getWholeWod(state, dispatch, undefined, id).then((result) =>{
      //setMyWod(wodPartListRenderer(result));
      console.log(result);
      //setTagList(result.tags);
      //console.log(myWodList[2]);
    });
  }

  

  const listItems = myWod.map(wod => {
    return (
      <div key={wod.id.toString()} className="mt-10 ">
        
        <WodPreview 
          wod={wod.wod} 
          update={update}
          showDeleteButton={true}
          showEditButton={false}
          showSpaceButton={false}
        />

      </div>
    );
  });

  

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row space-x-2">
        <Link to={"/woddetailmodify/"+prevWod.id} 
          state={{
            wodName: prevWod.name,
            owner: prevWod.owner,
            completed: prevWod.completed,
            page: location.state.page,
            isLast: location.state.isLast,
            list: filteredList,
            index: prevIndex,
            nextRequest: nextRequest,
            prevRequest: prevRequest,
            direction: prevIndex === 0 && prevRequest? "shift": "prev"
          }
        }>
          {filteredListIndex > 0 && "previous"}
        </Link>
        {"|"} 
        <Link to={"/woddetailmodify/"+nextWod.id} 
          state={{
            wodName: nextWod.name,
            owner: nextWod.owner,
            completed: nextWod.completed,
            page: location.state.page,
            isLast: location.state.isLast,
            list: filteredList,
            index: nextIndex,
            nextRequest: nextRequest,
            prevRequest: prevRequest,
            direction: "next"
          }
        }>
          {filteredListIndex < filteredList.length - 1 && "next"}
        </Link> 
      </div>
      <div className="flex flex-row space-x-2">
        <h3 class="text-3xl font-bold dark:text-white">{location.state.wodName || wodName}</h3>
        {isAdmin &&
          <DeleteWod 
            wodId={id}
            owner={location.state.owner}
            completed={location.state.completed}
            page={location.state.page}
            isLast={location.state.isLast}
            buttonText={"Delete Whole Wod"}
          />
        }
      </div>
      <div>{date}</div>
      {ownerName && <div><Link to={"/users/" + ownerId}>{ownerName}</Link></div>}
      <div className="flex flex-row space-x-6">
        <div className="flex flex-col space-y-1">
          {listItems}  
        </div>
        {/** ADD REMOVE TAGS FOR WHOLE WOD
         * 
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col space-y-2">
            <div className="flex justify-center">
              <h4 class="text-2xl font-bold dark:text-white">Tags</h4>
            </div>
            <RemoveTags
              relatedId={id}
              refreshTagList={refreshTagList}
              tagList={tagList}
              removeTagCallback={removeTagFromWod}
            />
          </div>
          <div className="flex flex-col space-y2">
            <div className="flex justify-center">
              <h4 class="text-2xl font-bold dark:text-white mt-6">Add Tags</h4>
            </div>
            <AddTags 
              relatedId={id} 
              refreshTagList={refreshTagList} 
              addTagCallback={addTagToWod}
            />
          </div>
        </div>
        */}
      </div>
    </div>
  );
}
export default WodDetailModify;