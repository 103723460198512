import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { saveTag, removeTagFromMovement, } from "../smartfit_common/apihelper/apihelper";
import { Button } from 'flowbite-react'

import { Link } from 'react-router-dom';

function RemoveTag ({relatedId, tagId, tagName, refreshList, removeTagCallback}) {
  const [state, dispatch] = useContext(UserContext);
  const [tag, setTag] = useState(tagName);

  const handelRemove = () => {

    removeTagCallback(state, dispatch, undefined, relatedId, tagId).then(() => {
      refreshList();
    }).catch(error => console.log(error.message));
  }
  
  return (
    <Button onClick={handelRemove} size='xs'>
        Remove
    </Button>
  );
}
export default RemoveTag;