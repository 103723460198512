import { Navigate } from 'react-router-dom';
import UserContext from '../usercontext/UserContext';
import { useContext, useEffect } from "react";

function Logout() {
  const [, dispatch] = useContext(UserContext);
  useEffect(() => {
    dispatch({
      type: "logout"
    });
  }, []);
  return <Navigate to={"/login"}/>;
}
export default Logout;