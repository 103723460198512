import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { apiCallPost,  } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { Button, TextInput, Label } from 'flowbite-react'

function CreateGeneratorSetting ({update, buttonLabel, type}) {
  const [state, dispatch] = useContext(UserContext);
  const [settingsName, setSettingsName] = useState("");
  const [show, setShow] = useState(false);

  const handleSave = () => {
    apiCallPost(state, dispatch, undefined, 
      "/addcustomsettings/",
      {
        name: settingsName,
        type: type,
        //settings: {wod_name: settingsName}
      }
    ).then(res => {
      console.log(res)
      update();
      setSettingsName("");
      setShow(false);
      //setSettings(JSON.stringify(res));
    }).catch(e => console.log(e.message));
  }
  
  return (
    (!show&&
      <div className='flex flex-row space-x-2 items-end '>
        <Button
          onClick={() => setShow(true)}
        >
          {buttonLabel || "Create New Setting"}
        </Button> 
        
      </div>
    )   
    ||
    (show&&
      <div className='flex flex-row space-x-2 items-end '>
        <div>
          <Label value={"Setting Name"}/>
          <TextInput 
            type="text" 
            value={settingsName}
            onChange={e => setSettingsName(e.target.value)}
          />
        </div>
        <Button
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            setShow(false);
            setSettingsName("");
          }}
        >
          Cancel
        </Button> 
      </div>
    )
  );
}
export default CreateGeneratorSetting;