import { Link } from "react-router-dom";
 
export function rootWodListRenderer(wods){
    const wodList = [];
    for(let i = 0; i < wods.length; i++){
        const wod = wods[i];
        const listElement = {
            id: wod.id,
            name: wod.name,
            description: wod.description,
            wod: wod,
            owner: wod.owner_id,
            isResultChecked: wod.is_result_checked,
            isGenerated: wod.is_generated,
            rating: wod.rating,
            isCompleted: wod.is_completed,
            creationDate: wod.creation_date.substring(0, 10),
            ownerName: wod.owner.username
        };
        wodList.push(listElement);
    }
    return wodList;
}


export function wodModeRenderer(wod){
    let str = "";
    if (wod.wod_mode === "ROUNDS" || wod.wod_mode === "ROUNDS FOR TIME") {
        str += wod.rounds?.toString() + " " + wod.wod_mode;
        if (wod?.timecap > 0) {
            str += " timecap: " + timeFormat(wod.timecap);
        }
    } else if (wod.wod_mode === "AMRAP" || wod.wod_mode === "EMOM") {
        str += timeFormat(wod.timecap) + " ";
        if (wod.wod_mode === "EMOM" && wod?.interval > 1) {
            str += " E" + wod?.interval.toString() + "MOM";
        } else {
            str += " " + wod.wod_mode;
        }
    } else if (wod.wod_mode === "CHIPPER" || wod.wod_mode === "FOR TIME") {
        str += wod.wod_mode;
        if (wod.timecap > 0) {
            str += " timecap: " + timeFormat(wod.timecap);
        }
    }

    return str;
}

export const timeFormat = (seconds) => {
    if (seconds % 30 === 0 && seconds > 30) {
        return (seconds / 60).toString() + " min";
    }
    return seconds?.toString() + " sec";
}

export function wodPartListRenderer(wods) {
  const wodList = [];
  for(let i = 0; i < wods.length; i++){
    const wod = wods[i];
    const listElement = {
      id: wod.id,
      name: wod.name,
      description: wod.description,
      wod: wod,
    };
    wodList.push(listElement);
  }
  return wodList;
}

export function subPartListRenderer(wodPart) {
  let list = [];
  wodPart.map(inst => {
    if (inst.is_multi_inst === true) {
      list = list.concat(multiInstRenderer(inst, 0, "  "));
    } else if (inst.is_single_inst === true) {
      list.push(singleInstRenderer(inst, 0, "  "));
    }
  });
  return list;
}

export function linkedSubPartListRenderer(wodPart, indentPrefix) {
  let list = [];
  const prefixStr = (indentPrefix? indentPrefix: "");

  wodPart.map(inst => {
    if (inst.is_multi_inst === true) {
      list = list.concat(linkedMultiInstRenderer(inst, 0, prefixStr));
    } else if (inst.is_single_inst === true) {
      list.push(linkedSingleInstRenderer(inst, 0, prefixStr));
    }
  });
  return list;
}

export function multiInstRenderer(multi, level, lvlPrefix) {
  let list = [];
  list.push({text: wodModeRendererLevel(multi, level, lvlPrefix), id: multi.id});
  multi.child.map(inst => {
    if (inst.is_multi_inst === true) {
      list = list.concat(multiInstRenderer(inst, level + 1, lvlPrefix));
    } else if (inst.is_single_inst === true) {
      //const newId = multi.id.toString() + inst.id.toString();
      list.push(singleInstRenderer(inst, level + 1, lvlPrefix));
    }
  });
  return list;
}
export function linkedMultiInstRenderer(multi, level, lvlPrefix) {
  let list = [];

  list.push({
    text: wodModeRendererLevel(multi, level, lvlPrefix), 
    id: multi.id,
    level: level
  });console.log(wodModeRendererLevel(multi, level, lvlPrefix) + " "+ level)
  multi.child.map(inst => {
    if (inst.is_multi_inst === true) {
      list = list.concat(linkedMultiInstRenderer(inst, level + 1, lvlPrefix));
    } else if (inst.is_single_inst === true) {
      //const newId = multi.id.toString() + inst.id.toString();
      list.push(linkedSingleInstRenderer(inst, level + 1, lvlPrefix));
    }
  });
  return list;
}


export function singleInstRenderer(single, level, lvlPrefix) {
  return {
    text: exerciseRenderer(single.exercises, level, lvlPrefix),
    id: single.id,
  };
}
export function linkedSingleInstRenderer(single, level, lvlPrefix) {
  return {
    exercises: linkedExerciseRenderer(single.exercises, level, lvlPrefix),
    id: single.id,
    level: level
  };
}

export function exerciseRenderer(exercises, level, lvlPrefix){
  let lvl_str = "";
  for(let i = 0; i < level; i++){
      lvl_str += lvlPrefix;
  }
  let ex_str = "";
  for(let i = 0; i < exercises.length; i++){
      ex_str += singleExStrGen(exercises[i]) + " + ";
  }
  ex_str = ex_str.slice(0,ex_str.length-3);

  return lvl_str + ex_str;
}

export function linkedExerciseRenderer(exercises, level, lvlPrefix){
  let lvl_str = "";
  const exList = [];
  for(let i = 0; i < level; i++){
      lvl_str += lvlPrefix;
  }
  for(let i = 0; i < exercises.length; i++){
      exList.push(linkedSingleExStrGen(exercises[i], i === 0? lvl_str: ""))
      exList.push(" + ")
  }
  exList.pop();

  return exList;
}

export function wodModeRendererLevel(wod, level, lvlPrefix){
 let lvl_str = "";
  for(let i = 0; i < level; i++){
      lvl_str += lvlPrefix;
  }
  let str = lvl_str;
  if (wod.wod_mode === "ROUNDS" || wod.wod_mode === "ROUNDS FOR TIME") {
    str += wod.rounds?.toString() + " " + wod.wod_mode;
    if (wod?.timecap > 0) {
      str += " timecap: " + timeFormat(wod.timecap);
    }
  } else if (wod.wod_mode === "AMRAP" || wod.wod_mode === "EMOM") {
    if (wod.timecap > 0) {
      str += timeFormat(wod.timecap) + " ";
    }
    if (wod.wod_mode === "EMOM" && wod?.interval > 1) {
      str += "E" + wod?.interval.toString() + "MOM";
    } else {
      str += wod.wod_mode;
    }
  } else if (wod.wod_mode === "CHIPPER" || wod.wod_mode === "FOR TIME") {
    str += wod.wod_mode;
    if (wod.timecap > 0) {
      str += " timecap: " + timeFormat(wod.timecap);
    }
  }

  return str;
}


export const distanceFormat = (cm) => {
  if (cm % 50 === 0 && cm > 50) {
    return (cm / 100).toString() + " m";
  }
  return cm?.toString() + " cm";
}


export function singleExStrGen(ex){
  let ex_str = "";
  if (ex.reps_male) {
    ex_str += ex.reps_male + (ex.reps_male !== ex.reps_female? "/" + ex.reps_female + " ":" ");
  }
  if (ex.time_male) {
    ex_str += timeFormat(ex.time_male) + (ex.time_male !== ex.time_female? "/" + timeFormat(ex.time_female) + " ":" ");
  }
  //TODO make link out of moevment.name here
  ex_str += ex.movement.name;
  //ex_str += <Link to={""+ex.movement.id}>{ex.movement.name}</Link>;
  if (ex.weight_male) {
    ex_str += " " + ex.weight_male + (ex.weight_male !== ex.weight_female? "/" + ex.weight_female + " kg ":" kg ");
  }
  if (ex.distance_male) {
    ex_str += " " + distanceFormat(ex.distance_male) +
      (ex.distance_male !== ex.distance_female? "/" +
        distanceFormat(ex.distance_female) + " ":" ");
  }
  if (ex.cal_male) {
    ex_str += " " + ex.cal_male.toString() + "cal" +
      (ex.cal_male !== ex.cal_female? "/" +
        ex.cal_male.toString() + "cal" + " ":" ");
  }
  return ex_str;
}

export function linkedSingleExStrGen(ex, lvlStr){
  let preExStr = "";
  if (ex.reps_male) {
    preExStr += ex.reps_male + (ex.reps_male !== ex.reps_female? "/" + ex.reps_female + " ":" ");
  }
  if (ex.time_male) {
    preExStr += timeFormat(ex.time_male) + (ex.time_male !== ex.time_female? "/" + timeFormat(ex.time_female) + " ":" ");
  }
  //TODO make link out of moevment.name here
  //<Link to={""+props.moveId}>{props.moveName}</Link>{" "}
  //ex_str += ex.movement.name;
  let postExStr = "";
  //ex_str += <Link to={ex.movement.id}>{ex.movement.name}</Link>;
  if (ex.weight_male) {
    postExStr += " " + ex.weight_male + (ex.weight_male !== ex.weight_female? "/" + ex.weight_female + " kg ":" kg ");
  }
  if (ex.distance_male) {
    postExStr += " " + distanceFormat(ex.distance_male) +
      (ex.distance_male !== ex.distance_female? "/" +
        distanceFormat(ex.distance_female) + " ":" ");
  }
  if (ex.cal_male) {
    postExStr += " " + ex.cal_male.toString() + "cal" +
      (ex.cal_male !== ex.cal_female? "/" +
        ex.cal_male.toString() + "cal" + " ":" ");
  }
  return (<>{lvlStr + preExStr}<Link to={"/movements/"+ex.movement.id}>{ex.movement.name}</Link>{postExStr}</>);
}
