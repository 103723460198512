import React, { useState, useContext, useEffect } from "react";
import { Modal, TextInput, Label, Button, Checkbox, Dropdown } from "flowbite-react";

function RenameModal ({title, onConfirm, buttonText, oldName}) {
    const [show, setShow] = useState(false);
    const [newName, setNewName] = useState("");

    function close () {
        setShow(false);
    }
  return (
    <React.Fragment>
    <Dropdown.Item
        onClick={() => setShow(!show)}
      >
        {buttonText}
      </Dropdown.Item>
    <Modal
        show={show}
        size="md"
        popup={true}
        onClose={() => setShow(false)}
    >
        <Modal.Header />
        <Modal.Body>
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
            {title}
            </h3>
            <div>
            <div className="mb-2 block">
                <Label
                htmlFor="oldName"
                value="Old Name"
                />
            </div>
            <TextInput
                id="oldName"
                value={oldName}
                disabled={true}
            />
            </div>
            <div>
            <div className="mb-2 block">
                <Label
                htmlFor="newName"
                value="New Name"
                />
            </div>
            <TextInput
                id="newName"
                required={true}
                value={newName}
                placeholder={"New Name"}
                onChange={e => setNewName(e.target.value)}
            />
            </div>

            <div className="w-full">
            <Button
              onClick={() => {
                onConfirm(close, newName);

              }}
            >
                {buttonText}
            </Button>
            </div>
              
        </div>
        </Modal.Body>
    </Modal>
</React.Fragment>
  );
}
export default RenameModal

