//import store from "../store/store";
import axios from "axios";
import {getUser, readObject, refreshToken, verifyToken} from "../authmanager/AuthManager";
//import {loginUser, setToken} from "../userslice/userSlice";

const confirmUser = async (state, dispatch) => {
  console.log("confirm user");
  if (state.refreshing) {
    console.log("already refreshing");
    return;
  }
  if (!state.loggedIn) {
    console.log("not logged in");
    throw new Error("User not logged in");
  }
  if(!await verifyToken(state.token.access)){
    dispatch({type: "setRefreshing", data: true});
    try {

      const token = await refreshToken(state.token.refresh);
      if (token != null) {
        dispatch({type: "setToken", data: {token: token}});     
      } else {
        console.log("there is no token");
      }
      
      return token;
    } catch(error) {
      console.log(error);
      return error;
    }
  }
  return state.token;
}

const getNewToken = async (state, dispatch) => {
  console.log("getNewToken");
  if (state.refreshing) {
    console.log("already refreshing");
    return;
  }
  dispatch({type: "setRefreshing", data: true});
  const token = await refreshToken(state.token.refresh);
  if (token) {
    dispatch({type: "setToken", data: {token: token}});
  }
  else {
    console.log("there is no token");
  }
  dispatch({type: "setRefreshing", data: false});
  return token;
}

export const getRandomGeneratedWod = async (state, dispatch, token) => {
  const retry = (token === undefined? true: false);

  const sessionUrl = '/get_random_wod/';
  try{
    const res = await axios.get(sessionUrl, {headers:getAxiosConfig(state, token).headers});
    console.log(res);

    return res.data;
  } catch (error) {
    console.log("API Helper - Get All Wod List - " + error.response.status);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getRandomGeneratedWod, state, dispatch,);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }    
    throw new Error(error);
  }
}

export const getWholeWod = async (state, dispatch, token, wod) => {
  const retry = (token === undefined? true: false);
  let sessionUrl = '/whole_wods/' + wod + "/";
  console.log(sessionUrl);
  let params = {}

  console.log("request params")
  console.log(params);

  
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(sessionUrl, {headers: getAxiosConfig(state, token).headers});
    return res.data;
  } catch (error) {
    console.log("API Helper - Get All Wod List - " + error.response.status);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getWholeWod, state, dispatch, wod);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }   
    throw new Error(error);
  }
}

export const getRootOfWod = async (state, dispatch, wod) => {

  let sessionUrl = '/get_root_of_wod/';
  console.log(sessionUrl);
  const params = {}
  if (wod !== undefined) {
    params.wod = wod;
  }
  console.log("request params")
  console.log(params);

  
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(sessionUrl, {params:params, headers: getAxiosConfig(state).headers});
    console.log(res);
    const wodList = res.data.child;

    console.log("API Helper");
    //console.log(wodList);
    console.log("API Helper ENDE");
    return res.data;
  } catch (error) {
    console.log("API Helper - Get All Wod List");
    console.log(error.response.status);
    throw new Error(error);
  }
}

export const getFirstWodPart = async (state, dispatch, wod) => {

  let sessionUrl = '/get_first_wodpart/';
  console.log(sessionUrl);
  const params = {}
  if (wod !== undefined) {
    params.wod = wod;
  }
  console.log("request params")
  console.log(params);

  
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(sessionUrl, {params: params, headers: getAxiosConfig(state).headers});
    console.log(res);
    const wodList = res.data.child;

    console.log("API Helper");
    //console.log(wodList);
    console.log("API Helper ENDE");
    return res.data;
  } catch (error) {
    console.log("API Helper - Get All Wod List");
    console.log(error.response.status);
    
    throw new Error(error);
  }
}


export const getMyWodList = async (state, dispatch, token, data, owner, completed, movement, tag, page) => {
  const retry = (token === undefined? true: false);
  let sessionUrl = '/roots/';
  if (data.movement)
    sessionUrl = '/whole_wods_movement/';
  if (data.tag)
    sessionUrl = '/whole_wods_tag/';
  
  try {
    const res = await axios.get(sessionUrl, {params: data, headers: getAxiosConfig(state, token).headers});
    const wodList = {
      wods: res.data.results,
      count: res.data.count,
      previous:res.data.previous,
      next:res.data.next
    }
    return wodList;
  } catch (error) {
    console.log("API Helper - Get All Wod List - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getMyWodList, state, dispatch, owner, completed, movement, tag, page);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const getAllWodList = async (state, dispatch, page) => {

  let session_url = '/wod_texts/';

  if(page != undefined  && page !== 0){
      session_url += "?page=" + page;
  }
  console.log(session_url);

  
  let wodList = {};
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(session_url, getAxiosConfig(state));

    wodList = {
      wods: res.data.results,
      count: res.data.count,
      previous:res.data.previous,
      next:res.data.next
    }

  } catch (error) {
    console.log("API Helper - Get All Wod List");
    console.log(error.response.status);
    throw new Error(error);
  }
  return wodList;
}

export async function addTagToWod (state, dispatch, token, wodId, tagId) {
  const retry = (token === undefined? true: false);

  const session_url = '/addtagtowod/';
  //console.log(session_url);
  
  const wodData = {
    wod: wodId,
    tag: tagId
    //user: state.userUrl
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state, token));
    return res.status;
  } catch (error) {
    console.log("API helper: add tag to wod - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(addTagToWod, state, dispatch, wodId, tagId);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function removeTagFromWod (state, dispatch, token, wodId, tagId) {
  const retry = (token === undefined? true: false);

  //await confirmUser(state, dispatch);
  const session_url = '/removetagfromwod/';
  //console.log(session_url);

  const wodData = {
    wod: wodId,
    tag: tagId
    //user: state.userUrl
  };
  
  console.log(wodData);
  try {
    const res = await axios.patch(session_url, wodData, getAxiosConfig(state, token));
    return res.status;
  } catch (error) {
    console.log("API helper: remove tag from wod - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(removeTagFromWod, state, dispatch, wodId, tagId);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const getAllMovements = async (state, dispatch, token, page, filter) => {
  const retry = (token === undefined? true: false);
  let session_url = '/movements/?ordering=name';
  if(page !== undefined  && page !== 0){
    session_url += "&page=" + page;
  }
  if (filter && filter !== "") {
    session_url += "&search=" + filter;
  }

  console.log(session_url);
  
  try{

    const res = await axios.get(session_url, getAxiosConfig(state, token));
    console.log(res);
    const moveList = {
      wods: res.data.results,
      count: res.data.count,
      previous:res.data.previous,
      next:res.data.next
    }
    return moveList;
  } catch (error) {
    console.log("API Helper - Get All Movement List " + error.message);
    console.log(error.response.status)
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(getAllMovements, state, dispatch, page, filter);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const getMovement = async (state, dispatch, token, movementId) => {
  const retry = (token === undefined? true: false);

  let session_url = '/movements/' + movementId;

  console.log(session_url);

  
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(session_url, getAxiosConfig(state));

    const moveList = {
      wods: res.data.results,
      count: res.data.count,
      previous:res.data.previous,
      next:res.data.next
    }
    return res.data;
  } catch (error) {
    console.log("API Helper - Get All Movement List");
    console.log(error.response.status);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getMovement, state, dispatch, movementId);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function addTagToMovement (state, dispatch, token, moveId, tagId) {
  const retry = (token === undefined? true: false);

  const session_url = '/addtagtomove/';
  //console.log(session_url);
  
  const wodData = {
    movement: moveId,
    tag: tagId
    //user: state.userUrl
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state, token));
    return res.status;
  } catch (error) {
    console.log("API helper: add tag to movement - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(addTagToMovement, state, dispatch, moveId, tagId);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function removeTagFromMovement (state, dispatch, token, moveId, tagId) {
  const retry = (token === undefined? true: false);

  const session_url = '/removetagfrommove/';
  //console.log(session_url);
  
  const wodData = {
    movement: moveId,
    tag: tagId
    //user: state.userUrl
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.patch(session_url, wodData, getAxiosConfig(state, token));
    return res.status;
  } catch (error) {
    console.log("API helper: add tag to movement - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(removeTagFromMovement, state, dispatch, moveId, tagId);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function saveMovement (state, dispatch, moveName) {
  const session_url = '/movements/';
  //console.log(session_url);
  
  const wodData = {
    name: moveName,
    //user: state.userUrl
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Enter Movement - " + error.message);
    throw new Error(error);
  }
}

export async function deleteMovement (state, dispatch, token, moveId) {
  const retry = (token === undefined? true: false);
  const session_url = '/movements/' + moveId + '/';
  //console.log(session_url);
  
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.delete(session_url, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: delete Movement - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(deleteMovement, state, dispatch, moveId);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function updateMovementName (state, dispatch, token, moveId, moveName) {
  const retry = (token === undefined? true: false);
  const session_url = '/movements/' + moveId + '/';
  //console.log(session_url);
  
  const wodData = {
    name: moveName,
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.patch(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Patch Movement - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(updateMovementName, state, dispatch, moveId, moveName);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function updateMovement (state, dispatch, token, moveId, data) {
  const retry = (token === undefined? true: false);
  const session_url = '/movements/' + moveId + '/';
  //console.log(session_url);
  
  const wodData = data;

  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.patch(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Patch Movement - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(updateMovementName, state, dispatch, moveId, data);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function mergeMovements (state, dispatch, moveId1, moveId2) {
  const session_url = '/mergemove/';
  //console.log(session_url);
  
  const wodData = {
    movement1: moveId1,
    movement2: moveId2
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: merge movement - " + error.message);
    throw new Error(error);
  }
}


export const getAllTags = async (state, dispatch, token, page, filter) => {
  const retry = (token === undefined? true: false);
  let session_url = '/tags/';
  session_url += '?ordering=name';
  if(page !== undefined  && page !== 0){
      session_url += "&page=" + page;
  }
  if (filter && filter !== "") {
    session_url += "&search=" + filter;
  }

  console.log(session_url);
  console.log(state);
  
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(session_url, getAxiosConfig(state, token));

    const tagList = {
      tags: res.data.results,
      count: res.data.count,
      previous:res.data.previous,
      next:res.data.next
    }
    return tagList;
  } catch (error) {
    console.log("API Helper - Get All Tags List - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        return await retryWithCallback(getAllTags, state, dispatch, page, filter);
      } catch (callbackError) {
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const getTag = async (state, dispatch, token, tagId) => {
  const retry = (token === undefined? true: false);
  let session_url = '/tag/' + tagId;

  console.log(session_url);

  
  try{
    //await confirmUser(state, dispatch);
    const res = await axios.get(session_url, getAxiosConfig(state, token));
/*
    const tag = {
      tags: res.data,
      count: res.data.count,
      previous:res.data.previous,
      next:res.data.next
    }*/
    return res.data;
  } catch (error) {
    console.log("API Helper - Get All Movement List");
    console.log(error.response.status);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getTag, state, dispatch, tagId);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }    
    throw new Error(error);
  }
}

export const setWodResultChecked = async (wod, isChecked, dispatch) => {
  
  const sessionUrl = '/whole_wods/' + wod.id + '/';
  const wodData = {is_result_checked: isChecked};
  console.log(wodData);
  try {
    //await confirmUser(dispatch);
    const result = await axios.patch(sessionUrl, wodData, getAxiosConfig());
    return result.status;
  } catch (error) {
    console.log("API Helper- Set Result Checked");
    console.log(error.message);
    throw new Error(error);
  }
}

export async function deleteWod(state, dispatch, token, id){
  const retry = (token === undefined? true: false);

  let sessionUrl = '/whole_wods/' + id + "/";
  console.log(sessionUrl);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.delete(sessionUrl, getAxiosConfig(state, token));
    console.log(res.status);
    return 200; 
  } catch (error ) {
    console.log("API Helper - delete Wod - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(deleteWod, state, dispatch, id);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }    
    throw new Error(error);
  }
}

export async function deleteUser (state, dispatch, tagId) {
  const session_url = '/auth/users/' + tagId + '/';
  //console.log(session_url);
  
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.delete(session_url, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: delete Tag - " + error.message);
    throw new Error(error);
  }
}

export async function saveTag (state, dispatch, token, tagName) {
  const retry = (token === undefined? true: false);

  const session_url = '/tags/';
  //console.log(session_url);
  
  const wodData = {
    name: tagName,
    //user: state.userUrl
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Enter Tag - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(saveTag, state, dispatch, tagName);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function deleteTag (state, dispatch, token, tagId) {
  const retry = (token === undefined? true: false);

  const session_url = '/tags/' + tagId + '/';
  //console.log(session_url);
  
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.delete(session_url, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: delete Tag - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(deleteTag, state, dispatch, tagId);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function updateTagName (state, dispatch, token, tagId, tagName) {
  const retry = (token === undefined? true: false);

  const session_url = '/tags/' + tagId + '/';
  //console.log(session_url);
  
  const wodData = {
    name: tagName,
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.patch(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Patch Tag - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(updateTagName, state, dispatch, tagId, tagName);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export async function updateTag (state, dispatch, token, tagId, data) {
  const retry = (token === undefined? true: false);

  const session_url = '/tags/' + tagId + '/';
  //console.log(session_url);
  
  const wodData = data;
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.patch(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Patch Tag - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(updateTagName, state, dispatch, tagId, data);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}


export async function saveWod (state, dispatch, wod, user) {
  
  const session_url = '/wodparser/';
  //console.log(session_url);
  
  let wodData = {
    wod_text: wod,
    user: state.userUrl
  };
  if (user !== undefined) {
    wodData.user = user;
  }
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state));
    return res.status;
  } catch (error) {
    console.log("API helper: Enter Wod - " + error.message);
    throw new Error(error);
  }
}

export async function rateWod (state, dispatch, token, wodId, rating) {
  const retry = (token === undefined? true: false);
  const session_url = '/ratewodpart/';
  //console.log(session_url);
  
  let wodData = {
    wod: wodId,
    rating: rating
  };
  
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const res = await axios.post(session_url, wodData, getAxiosConfig(state, token));
    return res.status;
  } catch (error) {
    console.log("API helper: rate Wod - " + error.message);
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(rateWod, state, dispatch, wodId, rating);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }    
    throw new Error(error);
  }
}

export const apiCallPost = async (state, dispatch, token, url, data) => {
  const retry = (token === undefined? true: false);
  try {
    const result = await axios.post(url, data, { headers: getAxiosConfig(state, token).headers});
      
    return result;
  } catch (error) {
    console.log("API Helper - api Call Get - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(
          apiCallPost, state, dispatch, url, data);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const apiCallPatch = async (state, dispatch, token, url, data) => {
  const retry = (token === undefined? true: false);
  try {
    const result = await axios.patch(url, data, getAxiosConfig(state, token));
      
    return result;
  } catch (error) {
    console.log("API Helper - api Call Get - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(
          apiCallPatch, state, dispatch, url, data);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const saveWodResult = async (wod, resultText, state, dispatch) =>{
  
  const session_url = '/whole_wods/' + wod + '/';
  console.log(session_url);
  //wod.result = resultText;
  const wodData = {result: resultText};
  try {
    //await confirmUser(state, dispatch);
    const result = await axios.patch(session_url, wodData, getAxiosConfig(state));
    return result.status;
  } catch (error) {
    console.log("API Helper- save wod result");
    console.log(error.message);
    throw new Error(error);
  }
}

export const completeWod = async (wod, state, dispatch) =>{
  
  const sessionUrl = '/whole_wods/' + wod.id + '/';
  console.log(sessionUrl);
  //wod.result = resultText;
  const wodData = {is_completed: !wod.is_completed};
  console.log(wodData);
  try {
    //await confirmUser(state, dispatch);
    const result = await axios.patch(sessionUrl, wodData, getAxiosConfig(state));
    return result.status;
  } catch (error) {
    console.log("API Helper- complete wod");
    console.log(error.message);
    throw new Error(error);
  }
}

export const getUserList = async (state, dispatch, token, isActive, page) => {
  let sessionUrl = "/users/";
  const retry = (token === undefined? true: false);
  sessionUrl += '?ordering=username';
  if (isActive !== undefined) {
    sessionUrl += '&is_active=' + isActive;
  }
  if (page !== undefined  && page !== 0) {
    sessionUrl += "&page=" + page;
  }
  try {
    const result = await axios.get(sessionUrl, getAxiosConfig(state, token));
    return result.data;
  } catch (error) {
    console.log("API Helper - get User List - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getUserList, state, dispatch, isActive, page);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const getCustomGeneratorSettings = async (state, dispatch, token, settingsId) => {
  let sessionUrl = "/getcustomsettings/";
  const retry = (token === undefined? true: false);

  const data = {
    id: settingsId
  }
  try {
    const result = await axios.get(sessionUrl, {params: data, headers: getAxiosConfig(state, token).headers});
      
    return result.data;
  } catch (error) {
    console.log("API Helper - getCustomGeneratorSettings - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(
          getCustomGeneratorSettings, state, dispatch, settingsId);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const apiCallGet = async (state, dispatch, token, url, data) => {
  const retry = (token === undefined? true: false);
  try {
    const result = await axios.get(url, {params: data, headers: getAxiosConfig(state, token).headers});
      
    return result.data;
  } catch (error) {
    console.log("API Helper - api Call Get - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(
          apiCallGet, state, dispatch, url, data);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw callbackError;
      }
    }
    throw error;
  }
}

export const apiCallDelete = async (state, dispatch, token, url, data) => {
  const retry = (token === undefined? true: false);
  try {
    const result = await axios.delete(url, {params: data, headers: getAxiosConfig(state, token).headers});
      
    return result.data;
  } catch (error) {
    console.log("API Helper - api Call Delete - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(
          apiCallDelete, state, dispatch, url, data);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

const retryWithCallback = async(callback, state, dispatch, ...callbackArgs) => {
  console.log(callbackArgs);
  try {
    const token = await refreshToken(state.token.refresh);
    //console.log("refreshToken " + token)
    if (token) {
      dispatch({type: "setToken", data: {token: token}});
      const res = await callback(state, dispatch, token, ...callbackArgs );
      console.log(res);
      return res;
    } else {
      console.log("there is no token");
    }
  } catch (refreshError) {
    console.log("API Helper - retry with token - refresh token - " + refreshError.message)
    throw refreshError;
  }
}

export const getUserDetail = async (state, dispatch, token, userId) => {
  const retry = (token === undefined? true: false);
  try {
    const result = await getUser(state, userId, token);
    return result;
  } catch (error) {
    console.log("API Helper - getUserDetail - " + error.message)
    if (retry && error.response.status === 401) {
      try {
        const res = await retryWithCallback(getUserDetail, state, dispatch, userId);
        return res;
      } catch (callbackError) {
        console.log("callback error " + callbackError.message);
        throw new Error(callbackError);
      }
    }
    throw new Error(error);
  }
}

export const setUserIsActive = async (state, dispatch, userId, isActive) => {
  const sessionUrl = '/users/' + userId + '/';
  console.log(sessionUrl);
  try {
    //await confirmUser(state, dispatch);
    const result = await axios.patch(sessionUrl, {is_active: isActive}, getAxiosConfig(state));
    return result.status;
  } catch (error) {
    console.log("API Helper - setUserIsActive - " + error.message)
    throw new Error(error);
  }
}

export function getAxiosConfig(state, token){
  //console.log("get axio config " + store.getState().user.accessToken);
  //console.log(token)
  return {
    headers: {
      'Authorization': "Bearer " + (token !== undefined? token: state.token.access),
      //'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json'
    }
  }
}
export function getAxiosConfigfromToken(token){
  //console.log("get axio config " + store.getState().user.accessToken);
  return {
    headers: {
      'Authorization': "Bearer " + token,
      //'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json'
    }
  }
}
