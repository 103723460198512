import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { deleteWod } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react'


function DeleteWod ({wodId, owner, completed, page, isLast, update, buttonText}) {
  const [state, dispatch] = useContext(UserContext);
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteWod(state, dispatch, undefined, wodId).then(() => {
      //props.update();
      let navstr = "/";
      if (owner)
        navstr += owner + "/";
      if (completed !== undefined)
        navstr += completed + "/";
      if (update)
        update();
      if (page && isLast !== undefined)
        navigate("/wodlist"+navstr, 
        {state: {
          owner: owner, 
          completed: completed, 
          page: (isLast? page-1: page)
        }});
    }).catch(error => console.log(error.message));
  }
  const confirmDelete = () => {
      setConfirm(true);
  }
  const cancelDelete = () => {
      setConfirm(false);
  }
  
  return (<>
    {
      !confirm && (
        <Button
          onClick={confirmDelete}
        >
          {buttonText}
        </Button>
      )
    }
    {
      confirm && (
        <>
          cofirm delete {" ------> "}
          <Button
            onClick={cancelDelete}
          >
           cancel
          </Button>
          <Button
            onClick={handleDelete}
          >
            D E L
          </Button>
        </>
      )
    }
    </>
  );
}
export default DeleteWod;