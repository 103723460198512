import { Link, Navigate } from 'react-router-dom';
import UserContext from '../usercontext/UserContext';
import { useContext } from "react";

function PrivateRoute ({children}) {
  const [state, ] = useContext(UserContext);
  const auth = state.userId;
  return auth ? children : "";
}
function PublicRoute ({children}) {
  const [state, ] = useContext(UserContext);
  const auth = state.userId;
  return !auth ? children : "";
}
function UserAdminroute ({children}) {
  const [state, ] = useContext(UserContext);
  const auth = state.userId;
  const userAdmin = state?.userGroups?.includes("user_admin")? true: false;
  return auth && userAdmin ? children : "";
}
function NavBar() {

  return (
    <div>
      <nav>
        <PublicRoute><Link to="/login">Login</Link> |{" "}</PublicRoute>
        <PrivateRoute><Link to="/allwods">All Wods List</Link> |{" "}</PrivateRoute>
        <PrivateRoute><Link to="/movements">Movements</Link> |{" "}</PrivateRoute>
        <PrivateRoute><Link to="/tags">Tags</Link> |{" "}</PrivateRoute>
        <UserAdminroute><Link to="/users">Users</Link> |{" "}</UserAdminroute>
        <PrivateRoute><Link to="/allwodlist">Wod List</Link> |{" "}</PrivateRoute>
        <PrivateRoute><Link to="/logout">Logout</Link> |{" "}</PrivateRoute>
        <PublicRoute><Link to="/register">Register</Link></PublicRoute>
      </nav>
    </div>
  );
}
export default NavBar;