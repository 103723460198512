import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getAllTags,getAllMovements, getTag, updateTag, removeTagFromMovement, addTagToMovement } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import EnterTag from './EnterTag';
import UpdateTag from './UpdateTag';
import { useParams, Link, useNavigate } from "react-router-dom";
import { isWodAdmin } from "../usercontext/UserContext";
import {Button, Card, TextInput, Textarea} from 'flowbite-react'
import { Badge, Pagination, Table, Checkbox, Label } from 'flowbite-react';
import UpdateMovement from "../movements/UpdateMovement";
import ConfirmModal from "./ConfirmModal";
import AddMovements from "../movements/AddMovements";



function Tag () {
  const [state, dispatch] = useContext(UserContext);
  const [movementList, setMovementList] = useState([]);
  const [wodList, setWodlist] = useState([]);
  const [page, setPage] = useState(1);
  const [tagName, setTagName] = useState("");
  const [maxPage, ] = useState(1);

  const [changeText, setChangeText] = useState(false);
  const [tagText, setTagText] = useState("");
  const [oldText, setOldText] = useState("");
  const wodAdmin = isWodAdmin(state);
  const { id } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    getTag(state, dispatch, undefined, id).then(result => {
      setMovementList(result.movements);
      setWodlist(result.wodparts);
      setTagName(result.name);
      console.log(result);
      setOldText(result.text);
      setTagText(result.text);
      
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [state, dispatch, id]);

  const update = () => {
    getTag(state, dispatch, undefined, id).then(result => {
      setMovementList(result.movements);
      setWodlist(result.wodparts);
      setTagName(result.name);
      console.log(result);
      setOldText(result.text);
      setTagText(result.text);
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
      navigate("/movements");

    });
  }

  function textChangeHandler() {
    let sendText = "no description";
    if (tagText.replace(/\s/g, '').length) {
      sendText = tagText
    }
    
    updateTag(state, dispatch, undefined, id, {text: sendText})
      .then(result => {
        setChangeText(false);
      })
      .catch(error => console.log(error.message));
  }

  const listTableItems = movementList.map((movement, i) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={movement.id.toString()}>
  
          <UpdateMovement 
            update={update} 
            moveId={movement.id} 
            moveName={movement.name}
            hideName={false}
            hideButtons={true}
          />
        </Table.Cell>

        <Table.Cell>
          <div className="flex justify-end">

            <ConfirmModal
              buttonText={"remove"}
              confirmText={"Remove " + movement.name + " from " + tagName}
              onConfirm={(close) => {
                removeTagFromMovement(state, dispatch, undefined, movement.id, id).then(res =>{
                  update();
                  close();
                }).catch(error => console.log(error.message));
                
              }}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });

  const onPageChange = (e) => {
    console.log(e)
    setPage(e);
  }

  return (
    <div className="flex flex-col space-y-3">
      <h3 class="text-3xl font-bold dark:text-white">
        {tagName}
        {wodAdmin &&
              <UpdateTag 
                  update={update} 
                  tagId={id} 
                  tagName={tagName}
                  hideName={true}
                  hideButtons={false}
                />
            }   
      </h3>
      <div className="flex items-start space-x-2">
        
        {(wodAdmin || tagText !== "no description") &&
          <Card>
            {wodAdmin && changeText &&
              <Textarea
              value={tagText}
              onChange={e => setTagText(e.target.value)}
              />
            }
            {!changeText && 
              <p>{tagText}</p>
            }
            {wodAdmin && !changeText &&
              <Button
              size={"xs"}
              onClick={() => setChangeText(true)}
              >
                edit
              </Button>
            }
            {wodAdmin && changeText &&
            <div className="flex flex-row space-x-2">
                <Button
                size={"xs"}
                onClick={textChangeHandler}
                >
                  save
                </Button>
                <Button
                size={"xs"}
                onClick={() => {
                  setChangeText(false);
                  setTagText(oldText);
                }}
                >
                  cancel
                </Button>
              </div>
            }
          </Card>
        }
        <Link to={"/wodpartlist/"} state={{tag: id, tagName: tagName}}>
          <h4 class="text-2xl font-bold dark:text-white">
            <p class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
              Show related Workouts
            </p>
          </h4>
        </Link>
      </div>
  
      
      <div className="flex flex-row space-x-3">
        <div className="flex flex-col space-y-2">

          <h4 class="text-2xl font-bold dark:text-white">Related Movements</h4>
          <Pagination
            currentPage={page}
            onPageChange={onPageChange}
            showIcons={true}
            totalPages={maxPage}
            />
          <div className="flex items-start">
            <Table striped={true} hoverable={true}>
              <Table.Head>
                
                <Table.HeadCell>
                  Movement
                </Table.HeadCell>
                <Table.HeadCell>
                  <div className="flex justify-end">
                    Remove from Tag
                  </div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {listTableItems}
              </Table.Body>
            </Table>
          </div>
          <Pagination
            currentPage={page}
            onPageChange={onPageChange}
            showIcons={true}
            totalPages={maxPage}
          />
        </div>
        <div className="flex justify-start">
          <AddMovements
            tagId={id}
            refreshMovementList={update}
            existingList={movementList}
          />
        </div>   
      </div>
    </div>
  );
}
export default Tag;