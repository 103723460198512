import React, { useState, useContext, useEffect } from "react";
import { Card,Textarea, Button, Badge, Tooltip } from 'flowbite-react'
import {Link} from 'react-router-dom';
import UserContext , { isAdminuser } from '../usercontext/UserContext';
import {  deleteWod, removeTagFromWod, getWholeWod, addTagToWod, rateWod, apiCallGet, apiCallPatch
} from "../smartfit_common/apihelper/apihelper";
import { linkedSubPartListRenderer,
  subPartListRenderer } from "../smartfit_common/wodutil/wodutil";
  import DeleteWod from "./DeleteWod";
import RemoveTags from "../tags/RemoveTags";
import AddTags from "../tags/AddTags";
import { ThumbDown, ThumbUp, Generated, Completed } from "../myIcons/MyIcons";

  const renderListItems = (wodPartList) => {

    const myWodPartList = linkedSubPartListRenderer(wodPartList);

    const listItems = myWodPartList.map(wod => {
      let x = 2 * wod.level; 
      const indentStr = `ml-${x}`;
      console.log(wod.text + " " + wod.level + " "+ x + " " + indentStr);
      return (<li key={wod.id} className={indentStr}>
     
        {wod.exercises !== undefined && (wod.exercises.map(e => e))}
        {wod.exercises === undefined &&(wod.text)}
    </li>
      
      );
    });
    return listItems
  }

  const renderIndentedListItems = (wodPartList) => {

    const myIndentedWodPartList = linkedSubPartListRenderer(wodPartList, "- ");

    const listItemsIndent = myIndentedWodPartList.map(wod => {
      let x = 2 * wod.level; 
      const indentStr = `ml-${x}`;
      console.log(wod.text + " " + wod.level + " "+ x + " " + indentStr);
      return (<li key={wod.id} >
     
        {wod.exercises !== undefined && (wod.exercises.map(e => e))}
        {wod.exercises === undefined &&(wod.text)}
    </li>
      
      );
    });
    return(listItemsIndent);  
  }

  const getListStr = (wodPartList) => {

    const myStringWodPartList = subPartListRenderer(wodPartList);

    let wodStr = "";
    myStringWodPartList.forEach(wod => {
      console.log(wod)
      let exStr = "";
      if (wod.exercises !== undefined){

        console.log(wod.exercises)
        for (let e in wod.exercises){
          console.log(e)
          exStr += e.toString();
        }
      }
      if (wod.exercises === undefined)
        wodStr += wod.text;
      wodStr += exStr + "\n";
    })
    console.log(wodStr)
    return wodStr
  }

 //generated

  

  
function WodPreview ({wod, update, showDeleteButton, showEditButton, showSpaceButton}) {
  const wodPart = wod.child;
  console.log(wod);
  console.log(showDeleteButton, showEditButton, showSpaceButton);
  const [myListItems, setMyListItems] = useState(renderListItems(wodPart));
  const [showIndented, setShowIndented] = useState(false);
  const [wodText, setWodText] = useState(getListStr(wodPart));
  const [enableEdit, setEnableEdit] = useState(false);
  const [state, dispatch] = useContext(UserContext);
  const isAdmin = isAdminuser(state);
  const [tagList, setTagList] = useState(wod.tags); 
  const [showAddTags, setShowAddTags] = useState(false);
  const [rating, setRating] = useState(wod.rating);
  const [favourite, setFavourite] = useState(wod.is_favourite);

/* get wod from db with use effect
  useEffect(() => {
    getWholeWod(state, dispatch, undefined, wod.id).then((result) => {
      console.log(result);
      setMyListItems(renderListItems(result.child));
      setWodText(getListStr(result.child));
      setTagList(result.tags);
      setRating(result.rating);
    }).catch(e => console.log(e.message));
  }, [wod.id, state, dispatch]);
//*/
  const refreshTagList = () => {
    getWholeWod(state, dispatch, undefined, wod.id).then((result) =>{
      console.log(result);
      setTagList(result.tags);
    }).catch(e => console.log(e.message));
  }

  const toggleRating = (saveRating) => {
    rateWod(state, dispatch, undefined, wod.id, saveRating).then(result => { //TODO Rating has to be improved
      // also rate parent wod, since it is only wrapper for generated wodpart
      rateWod(state, dispatch, undefined, wod.parent_id, saveRating).then(res => {
        console.log(res);
        setRating(saveRating);
      }).catch(err => console.log(err.message));
    }).catch(e => console.log(e.message));
  }

  const toggleThumbUp = (active) => {
    console.log("toggleThumbUp " + active);
    const saveRating = active ? 0 : 1;
    toggleRating(saveRating);
  }

  const toggleThumbDown = (active) => {
    console.log("toggleThumbDown " + active);
    const saveRating = active ? 0 : -1;
    toggleRating(saveRating);
  }

  const toggleFavourite = () => {
    console.log("add to favourite");
    apiCallPatch(state, dispatch, undefined, "whole_wods/"+wod.id+"/", {
      is_favourite: !favourite,
    }).then(result => {
      //also rate parent
      apiCallPatch(state, dispatch, undefined, "whole_wods/"+wod.parent_id+"/", {
        is_favourite: !favourite,
      }).then(res => {
        setFavourite(!favourite);
        console.log(res);
      }).catch(err => console.log(err.message));
      console.log(result);
    }).catch(e => console.log(e.message));
  }

  return (
    <div className="flex flex-col space-y-2 mt-6">
      <div className="flex flex-row space-x-3">  
        <h4 class="text-2xl font-bold dark:text-white">
          <Link to={"/wodpartdetail/"+wod.id} state={{wod: wod}}>
            {wod.name + " " + wod.description}
          </Link>
          
        </h4>
  
          <div className="flex flex-row space-x-3">
            {wod.is_generated && <Generated color={"currentColor"}/>}
            {wod.is_completed &&  <Completed color={"currentColor"}/>}
            {
              //(wod.rating > 0 && <ThumbUp active={true}/>) || (wod.rating < 0 && <ThumbDown active={true}/>)
            }
            <ThumbUp 
              active={rating > 0}
              toggleHandler={toggleThumbUp}
            />
            <ThumbDown 
              active={rating < 0}
              toggleHandler={toggleThumbDown}
            />
            <Badge
              onClick={toggleFavourite}
              className={"cursor-pointer"}
            >
              {!favourite?"add to ":"remove from "}favourites
            </Badge>
          </div>
 
      </div>
      <div className="flex items-start">
        <div className="flex flex-row space-x-2">
          {enableEdit && 
            <Textarea
            value={wodText}
            rows={myListItems.length + 1}
            spellCheck={false}
            onChange={(e) => {
              setWodText(e.target.value);
            }}
            />
          }
          {!enableEdit && 
            <Card>
              <ul 
              class="space-y-1 max-w-md list-none list-inside text-gray-500 dark:text-gray-400">
                {myListItems}  
              </ul>
            </Card>
          }
        
          <div className="flex flex-col space-y-2">
            <RemoveTags
              relatedId={wod.id}
              refreshTagList={refreshTagList}
              tagList={tagList}
              removeTagCallback={removeTagFromWod}
            />
            <Button size ={"sm"}
              onClick={() => {
                setShowAddTags(!showAddTags);
              }}
            >
              {showAddTags? "Done": "add Tags"}
            </Button>
          </div>
        </div>
        {showAddTags && 
          <AddTags
          relatedId={wod.id} 
          refreshTagList={refreshTagList} 
          addTagCallback={addTagToWod}
          />
        }
      </div>
      <div className="flex flex-row space-x-1">
        {!enableEdit && 
          <div className="flex flex-row space-x-1">
            {showSpaceButton &&
              <Button
              size={"sm"}
              onClick={() =>{
                setShowIndented(!showIndented);
                if (!showIndented)
                  setMyListItems(renderIndentedListItems(wod.child));
                else
                  setMyListItems(renderListItems(wod.child));
              }}
              >
                show/hide spaces
              </Button>
            }
            {showEditButton &&
              <Button size={"sm"}
                onClick={() => {
                  setEnableEdit(!enableEdit);
                }}
              >
                edit
              </Button>
            }
          </div>
        }
        {enableEdit &&
          <div className="flex flex-row space-x-1">
            <Button size={"sm"}
              onClick={() => {
                setEnableEdit(!enableEdit);
                // logic to change wod
              }}
              >
              save
            </Button>
            <Button size={"sm"}
              onClick={() => {
                setEnableEdit(!enableEdit);
                // logic to change wod
              }}
              >
              cancel
            </Button>
          </div>
        }
        {showDeleteButton && isAdmin &&
          <DeleteWod 
            wodId={wod.id}
            update={update}
            buttonText={"delete part"}
          />
        }
        
      </div>
    </div>
  );
}
export default WodPreview;