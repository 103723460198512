import React, {useEffect, useState, useContext} from "react";
import {getMyWodList} from "../smartfit_common/apihelper/apihelper";
import { rootWodListRenderer } from '../smartfit_common/wodutil/wodutil';
import UserContext from '../usercontext/UserContext';
import NavBar from "../navbar/NavBar";
import { useParams, useLocation, Link } from "react-router-dom";
import { Badge, Pagination, Table, Checkbox, Label, Button, TextInput} from 'flowbite-react';
import { Completed, Generated, ThumbDown, ThumbUp } from "../myIcons/MyIcons";



export const WodList = () => {
  const [state, dispatch] = useContext(UserContext);
  const [myWodList, setMyWodList] = useState({wods: []});

  const { owner, completed } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(location.state?.page? location.state.page: 1);
  const [maxPage, setMaxPage] = useState(1);
  const [filterCompleted, setFiCompleted] = useState(false);
  const [filterNotCompleted, setFiNotCompleted] = useState(false);
  const [filterGenerated, setFiGemerated] = useState(false);
  const [filterRated, setFiRated] = useState(false);
  const [filterRatedGood, setFiRatedGood] = useState(false);
  const [filterRatedBad, setFiRatedBad] = useState(false);
  const [filterNotGenerated, setFiNotGenerated] = useState(false);
  const [filterFavourite, setFiFavourite] = useState(false);
  const [resultNumber, setResultNumber] = useState(0);
  const [orderingField, setOrderingField] = useState("-");
  const [wodNameFilter, setwodNameFilter] = useState("");
  const [nextRequest, setNextRequest] = useState(undefined);
  const [prevRequest, setPrevRequest] = useState(undefined);
  console.log(location);


  useEffect(() => {
  //  getMyWodList(state, dispatch, undefined, owner, filterCompleted, 
  //    location.state?.movement, undefined, page).then((result) =>{
    let rating = undefined, generated = undefined, completed = undefined, favourite = undefined;
    if (filterRated)
      rating = 0;
    if (filterRatedBad)
      rating = -1;
    if (filterRatedGood)
      rating = 1;
    if (filterGenerated)
      generated = true;
    if (filterNotGenerated)
      generated = false;
    if (filterCompleted)
      completed = true;
    if (filterNotCompleted)
      completed = false;
    if (filterFavourite)
      favourite = true;
    getMyWodList(state, dispatch, undefined, {
      owner: owner, 
      is_completed: completed,
      rating: rating, 
      is_generated: generated,
      movement: location.state?.movement, 
      page: page,
      ordering: orderingField + "creation_date",
      search: wodNameFilter,
      username_only: undefined,
      wodname_only: undefined,
      is_favourite: favourite,
    }).then((result) =>{
        setMyWodList({...result, wods: rootWodListRenderer(result.wods)});
        console.log(result)
        setResultNumber(result.count);
        setNextRequest(result.next);
        setPrevRequest(result.previous);
        console.log(result.next);
        const calcMaxPage = () => {
          if (result.count <= result.wods.length) 
              return 1;
          if (result.next === null)
              return page;
          return Math.ceil(result.count / result.wods.length);
        }
        setMaxPage(calcMaxPage);
    }).catch(e => console.log(e.message));
  }, [owner, completed, state, dispatch, page, location, filterCompleted, filterGenerated, filterRated, filterRatedGood,
  filterRatedBad, filterNotGenerated, filterNotCompleted, orderingField, wodNameFilter, filterFavourite ]);

  console.log(myWodList);
  
  const listTableItems = myWodList.wods.map((wod, index) => {
    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={wod.id}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" key={wod.id.toString()}>
          <Link to={"/woddetailmodify/"+wod.id} 
            state={{
              wodName: wod.name,
              owner: owner,
              completed: completed,
              page: page,
              isLast: (myWodList.wods.length === 1 && page > 1? true: false),
              list: myWodList.wods,
              index: index,
              nextRequest: nextRequest,
              prevRequest: prevRequest
            }}>
            {wod.id}
          </Link> 
        </Table.Cell>
        <Table.Cell >
          <Link to={"/woddetailmodify/"+wod.id} 
            state={{
              wodName: wod.name,
              owner: owner,
              completed: completed,
              page: page,
              isLast: (myWodList.wods.length === 1 && page > 1? true: false),
              list: myWodList.wods,
              index: index,
              nextRequest: nextRequest,
              prevRequest: prevRequest
            }}>
            {wod.name}{wod.name === "" && (<>-no name-</>)}
          </Link> 
        </Table.Cell>
        <Table.Cell>
          {wod.ownerName}
        </Table.Cell>
        <Table.Cell>
          {wod.isGenerated? <Generated/>: "-"}
        </Table.Cell>
        <Table.Cell>
          {wod.rating === -1? <ThumbDown active={true}/>: wod.rating === 1? <ThumbUp active={true}/>: "-"}
        </Table.Cell>
        <Table.Cell>
          {wod.creationDate}
        </Table.Cell>
        <Table.Cell>
          {wod.isCompleted? <Completed/>: "-"}  
        </Table.Cell>
      </Table.Row>
    );
  });
  


  const onPageChange = (p) => {
    setPage(p);
  }

  return (
    <div>
      {owner && (
        <Link to={"/users/"+owner}>
          <h3 class="text-3xl font-bold dark:text-white">
            {location.state.userName}
          </h3>
        </Link>
      )}
      {owner && (
        <p><Link to={"/wodlist/"+owner} state={{userName: location.state?.userName}}>
          {completed === undefined && (<b>All</b>)}
          {completed !== undefined && (<>All</>)}{" | "}
        </Link>
        <Link to={"/wodlist/"+owner+"/true"} state={{userName: location.state?.userName}}>
          {completed === "true" && (<b>Completed</b>)}
          {completed !== "true" && (<>Completed</>)}{" | "}
        </Link>
        <Link to={"/wodlist/"+owner+"/false"} state={{userName: location.state?.userName}}>
          {completed === "false" && (<b>New</b>)}
          {completed !== "false" &&(<>New</>)}
        </Link></p>
      )}
      <div className="flex flex-row space-x-3">
        <div className="flex flex-col space-y-1">
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterCompleted}
              onChange={() => setFiCompleted(!filterCompleted)}
            />
            <Label value={"completed"}/>       
          </div>
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterNotCompleted}
              onChange={() => setFiNotCompleted(!filterNotCompleted)}
            />
            <Label value={"not completed "}/>       
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterGenerated}
              onChange={() => setFiGemerated(!filterGenerated)}
            />
            <Label value={"generated"}/>       
          </div>
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterNotGenerated}
              onChange={() => setFiNotGenerated(!filterNotGenerated)}
            />
            <Label value={"not generaterd"}/>       
          </div>
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterFavourite}
              onChange={() => setFiFavourite(!filterFavourite)}
            />
            <Label value={"favourites"}/>       
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterRatedGood}
              onChange={() => setFiRatedGood(!filterRatedGood)}
            />
            <Label value={"good"}/>       
          </div>
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterRatedBad}
              onChange={() => setFiRatedBad(!filterRatedBad)}
            />
            <Label value={"bad"}/>       
          </div>
          <div className="flex justify-start space-x-1">
            <Checkbox
              checked={filterRated}
              onChange={() => setFiRated(!filterRated)}
            />
            <Label value={"not rated"}/>       
          </div>
        </div>
        <div>
        <Label value={"Search:"}/>
          <TextInput 
            type="text"
            value={wodNameFilter}
            onChange={e => {
              setwodNameFilter(e.target.value);
              setPage(1);
            }}
          />
        </div>
      </div>
      <p><Label value={"Results: "}/>{resultNumber}</p>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
      <Table striped={true} hoverable={true}>
        <Table.Head>
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Owner</Table.HeadCell>
          <Table.HeadCell>Generated</Table.HeadCell>
          <Table.HeadCell>Rating</Table.HeadCell>
          <Table.HeadCell>
            <Button
              size={"xs"}
              onClick={() => {
                if (orderingField === "")
                  setOrderingField("-");
                else
                  setOrderingField("");
              }}
            >
              {orderingField}Creation Date

            </Button>
            </Table.HeadCell>
          <Table.HeadCell>Completed</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {listTableItems}
        </Table.Body>
      </Table>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        showIcons={true}
        totalPages={maxPage}
      />
    </div>
  );
}
