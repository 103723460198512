import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';

import { 
  apiCallGet, apiCallPatch, apiCallPost
} from "../smartfit_common/apihelper/apihelper";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom';
import { subPartListRenderer } from "../smartfit_common/wodutil/wodutil";
import AddTags from "../tags/AddTags";
import RemoveTags from "../tags/RemoveTags";
import { Button, Badge, Table, Select, ToggleSwitch, Tooltip } from 'flowbite-react';
import { TextInput, Label } from 'flowbite-react';
import FetchSelector from "./FetchSelector";
import { Children } from 'react';
import MovementDeSelector from "./MovementDeSelector";
import StaticSelector from "./StaticSelector";
import SingleItemFetchSelector from "./SingeItemFetchSelector";
import SingleItemStaticSelector from "./SingeItemStaticSelector";
import { settingsMap, createInitialSettingState } from "./settingConstants";


function ListItems({children}) {
  return (
    <div className="flex flex-wrap space-x-1 space-y-1">
      {Children.map(children, child => child)}
    </div>
  );
}

function Rule({children, deleteRule}) {
  return (
    <Table.Row >
      <Table.Cell>
        <div className="flex flex-row space-x-2">
          <Button
            size={"xs"}
            onClick={() => deleteRule()}
          >
            remove rule
          </Button>
          <ListItems>
            {Children.map(children, child => child)}
          </ListItems>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

function EditGeneratorSettings() {
  const location = useLocation();
  const hideList = location.state.hideList;
  const showList = location.state.showList;
  console.log(location.state);
  console.log(showList);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(createInitialSettingState(settingsMap));
  //console.log(createInitialSettingState(settingsMap));
  //const [settings, setSettings] = useState({});
  const [settingsName, setSettingsName] = useState("");
  const [state, dispatch] = useContext(UserContext);
  const { id } = useParams();
  //console.log(formData);
  useEffect(() => {
    apiCallGet(state, dispatch, undefined, 
      "/getcustomsettings/",
      {
        id: id,
      }
    ).then(res => {
      console.log(res)
      //setSettings(res.settings);
      setSettingsName(res.name);
      if (Object.keys(res.settings).length > 0)
        setFormData(res.settings);
    }).catch(e => console.log(e.message));
  }, [dispatch, id, state]);

  const saveSettings = () => {
    apiCallPatch(state, dispatch, undefined, "/changecustomsettings/",
      {
        id: id,
        name: settingsName,
        settings: JSON.stringify(formData),
      }
    ).then(res => {
      //console.log(res);
      navigate("/ratewodpart")
    }).catch(e => console.log(e.message));
  };

  const handleInputChange = (e, key) => {
    //console.log(e)
    const value = e.target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const update = (value, key) => {

    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const updateList = (value, key, index) => {
    const list = formData[key];
    list[index] = value
    setFormData({
      ...formData,
      [key]: list,
    });
    //console.log(formData);
  };

  const getInputType = (key, value) => {
    console.log(key, value);
    if (key.includes("combine")) {
      return 'listlist'
    }
    if (Array.isArray(value)) {
      return 'select';
    }
    if (typeof value ==="object" && value !==null) {
      return "object"
    }
    return 'text';
  };

  const renderInput = (key, value, keyInfo) => {
    console.log(key, value, keyInfo);
    const label = keyInfo.label? keyInfo.label: key;
    //const inputType = getInputType(key, value);
    if (keyInfo.type.includes("list list")) {
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
          <Button
            size={"xs"}
            onClick={() => {
              value.push([]);
              update(value, key);
          }}>
            new rule
          </Button>
            <Table>
              <Table.Body className="divide-y">
                {value.map((rule, i ) => {
                  //console.log(rule);
                  return <Rule deleteRule={() => {
                    value.splice(i, 1);
                    update(value, key);
                  }}
                  key={key+i}
                  >
                      <FetchSelector
                        selectedMovements={rule}
                        settingsUpdate={updateList}
                        itemKey={key}
                        index={i}
                        url={keyInfo.url}
                      />
                      <MovementDeSelector
                        selectedMovements={rule}
                        settingsUpdate={updateList}
                        itemKey={key}
                        index={i}
                      />
                      <ListItems>
                        {rule.map((item,i) => (
                          <Badge key={key+i}>
                            {item}
                          </Badge>
                        ))}
                      </ListItems>
                  </Rule>
                })}
              </Table.Body>
            </Table>  
          </Table.Cell>
        </Table.Row>
      );
    }

    if (keyInfo.type === "allowed_weights movement dict") {
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
          <SingleItemFetchSelector
            selectedMovements={Object.keys(value)}
            settingsUpdate={(value, key) => {
            
              setFormData({...formData,
                allowed_weights: {...formData.allowed_weights,
                  [key]: {...formData.allowed_weights[key],
                    [value]: [0,0],
                  }
                }
              });
              //console.log(formData);
            }}
            itemKey={key}
            url={keyInfo.url}
            query={keyInfo.query}
          />
            <Table>
              <Table.Body className="divide-y">
                {Object.keys(value).map((movementName) => {
                  //console.log(movementName);
                  return <Rule key={"rule"+key+movementName} deleteRule={() => {
                    delete value[movementName];
                    setFormData({...formData,
                      allowed_weights: {...formData.allowed_weights,
                        [key]: value,
                      }
                    });
                  }}>
                    <div className="flex flex-row space-x-2">
                      <Badge  key={key+movementName}>{movementName}</Badge>
                      <div className="flex flex-row space-x-1">
                        <Select className="w-16"
                          value={value[movementName][0]}
                          onChange={(e) => 
                            setFormData({...formData,
                              allowed_weights: {...formData.allowed_weights,
                                [key]:{...formData.allowed_weights[key],
                                  [movementName]: [parseFloat(e.target.value), value[movementName][1]]
                                }
                              },
                            })
                          }
                        >
                          {keyInfo.choices.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Select>
                        <Select className="w-16"
                          width={10}
                          value={value[movementName][1]}
                          onChange={(e) => 
                            setFormData({...formData,
                              allowed_weights: {...formData.allowed_weights,
                                [key]:{...formData.allowed_weights[key],
                                  [movementName]: [value[movementName][0], parseFloat(e.target.value)]
                                }
                              },
                            })
                          }
                        >
                          {keyInfo.choices.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </Rule>
                })}
              </Table.Body>
            </Table>  
          </Table.Cell>
        </Table.Row>
      );
    }
    if (keyInfo.type === "allowed_weights tag dict") {
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
            <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
          <SingleItemStaticSelector
            selectedMovements={Object.keys(value)}
            settingsUpdate={(value, key) => {
              //console.log(key, value);
              setFormData({...formData,
                allowed_weights: {...formData.allowed_weights,
                  [key]: {...formData.allowed_weights[key],
                    [value]: [],
                  }
                }
              });
              //console.log(formData);
            }}
            allowedValues={keyInfo.allowedValues}
            itemKey={key}
            url={keyInfo.url}
          />
            <Table>
              <Table.Body className="divide-y">
                {Object.keys(value).map((equipment) => {
                  //console.log(equipment);
                  return <Rule  key={"rule"+key+equipment} deleteRule={() => {
                    delete value[equipment];
                    setFormData({...formData,
                      allowed_weights: {...formData.allowed_weights,
                        [key]: value,
                      }
                    });
                  }}>
                    <div className="flex flex-row space-x-2">
                      <Badge key={key+equipment}>{equipment}</Badge>
                      <div className="flex flex-wrap">
                        {value[equipment].map((weight, index) => 
                          <Select className="w-14" key={key+"select"+index}
                            value={weight}
                            onChange={(e) => {
                              const newList = [...formData.allowed_weights[key][equipment]];
                              if (e.target.value === "0") {
                                newList.splice(index, 1);
                              } else {
                                newList[index] = parseFloat(e.target.value);
                                newList.sort((a, b) => a - b);
                              }

                              setFormData({...formData,
                                allowed_weights: {...formData.allowed_weights,
                                  [key]: {...formData.allowed_weights[key],
                                    [equipment]: newList,
                                  }
                                },
                              })
                            }}
                          >
                            {keyInfo.choices.map((option) => {
                              if (formData.allowed_weights[key][equipment].includes(option) && option !== weight) {
                                return undefined;
                              }
                              return (
                                <option key={option} value={option}>
                                  {option === 0? "--": option}
                                </option>
                              );
                            })}
                            
                          </Select>
                        )}
                        <Select className="w-14"
                            value={0}
                            onChange={(e) => {
                              const newList = [...formData.allowed_weights[key][equipment]];
                              if (e.target.value === "0") {
                                //newList.splice(index, 1);
                              } else {
                                newList.push(parseFloat(e.target.value));
                                newList.sort((a, b) => a - b);
                              }

                              setFormData({...formData,
                                allowed_weights: {...formData.allowed_weights,
                                  [key]: {...formData.allowed_weights[key],
                                    [equipment]: newList,
                                  }
                                },
                              })
                            }}
                          >
                            {keyInfo.choices.map((option) => {
                              if (formData.allowed_weights[key][equipment].includes(option) ) {
                                return undefined;
                              }
                              return (
                                <option key={option} value={option}>
                                  {option === 0? "--": option}
                                </option>
                              );
                            })}
                            
                          </Select>
                      </div>
                    </div>
                  </Rule>
                })}
              </Table.Body>
            </Table>  
          </Table.Cell>
        </Table.Row>
      );
    }
    if (key === "movement_probabilities") {
      const movementNameList = [];
      for (const m of value)
        movementNameList.push(m[0]);
      //console.log(movementNameList);
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
            <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
            <SingleItemFetchSelector
              selectedMovements={movementNameList}
              settingsUpdate={(value, key) => {
                //console.log(key, value);
                const newList = [...formData[key]];
                newList.push([value, 1]);
                setFormData({...formData,
                    [key]: newList,
                      
                });
                //console.log(formData);
              }}
              itemKey={key}
              url={keyInfo.url}
            />
            <Table>
              <Table.Body className="divide-y">
                {value.map((movementName, index) => {
                  //console.log(movementName);
                  return <Rule key={"rule"+key+index} deleteRule={() => {
                    value.splice(index, 1)
                    setFormData({...formData,
                        [key]: value,
                      }
                    );
                  }}>
                    <div className="flex flex-row space-x-2">
                      <Badge key={key+index}>{movementName[0]}</Badge>
                      <div className="flex flex-row space-x-1">
                        <Select className="w-16"
                          value={movementName[1]}
                          onChange={(e) => {
                            const newList = [...formData[key]];
                            newList[index][1] = parseFloat(e.target.value);
                            setFormData({...formData,
                                [key]:newList,
                              }
                            )}
                          }
                        >
                          {keyInfo.choices.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </Rule>
                })}
              </Table.Body>
            </Table>  
          </Table.Cell>
        </Table.Row>
      );
    }
    if (key === "std_weight") {
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
          <SingleItemStaticSelector
            selectedMovements={Object.keys(value)}
            settingsUpdate={(value, key) => {
              //console.log(key, value);
              setFormData({...formData,
                  [key]: {...formData[key],
                    [value]: 0,
                  }
              });
              //console.log(formData);
            }}
            allowedValues={keyInfo.allowedValues}
            itemKey={key}
            url={keyInfo.url}
          />
            <Table>
              <Table.Body className="divide-y">
                {Object.keys(value).map((equipment,i) => {
                  //console.log(equipment);
                  return <Rule key={"rule"+key+i} deleteRule={() => {
                    delete value[equipment];
                    setFormData({...formData,
                      allowed_weights: {...formData.allowed_weights,
                        [key]: value,
                      }
                    });
                  }}>
                    <div className="flex flex-row space-x-2">
                      <Badge key={key+equipment}>{equipment}</Badge>
                      <div className="flex flex-row space-x-1">
                        <Select className="w-14"
                          value={formData[key][equipment]}
                          onChange={(e) => {
                            setFormData({...formData,
                                [key]: {...formData[key],
                                  [equipment]: parseFloat(e.target.value),
                                }
                              },
                            )
                          }}
                        >
                          {keyInfo.choices.map((option) => {
                            return (
                              <option key={option} value={option}>
                                {option === 0? "--": option}
                              </option>
                            );
                          })}
                          
                        </Select>
                      </div>
                    </div>
                  </Rule>
                })}
              </Table.Body>
            </Table>  
          </Table.Cell>
        </Table.Row>
      );
    }
    if (key === "target_time_min") {
      // Render a select input for array values
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell >
            <div className="flex flex-row space-x-1">
              <Select className="w-14"
                value={formData[key][0]}
                onChange={(e) => 
                  setFormData({
                    ...formData,
                    [key]: [parseInt(e.target.value), formData[key][1]],
                  })
                }
              >
                {keyInfo.allowedValues.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
              <Select className="w-14"
                value={formData[key][1]}
                onChange={(e) => {
                  //handleInputChange(e, key)
                  setFormData({
                    ...formData,
                    [key]: [formData[key][0], parseInt(e.target.value)],
                  });
                }}
              >
                {keyInfo.allowedValues.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </div>
          </Table.Cell>
        </Table.Row>

      );
    }

    if (keyInfo.type.includes("list") && keyInfo.selectMany && "allowedValues" in keyInfo) {
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
            <div className="flex flex-row space-x-1">
              <StaticSelector
                selectedMovements={formData[key]}
                settingsUpdate={update}
                itemKey={key}
                allowedValues={keyInfo.allowedValues}
              />
              <MovementDeSelector
                selectedMovements={formData[key]}
                settingsUpdate={update}
                itemKey={key}
              />
              <ListItems>
                {value.map((item) => (
                  <Badge  key={key+item}>
                    {item}
                  </Badge>
                ))}
              </ListItems>
            </div>  
          </Table.Cell>
        </Table.Row>
      );
    }

    if (keyInfo.type === "list tag" || keyInfo.type === "list movement") {
      // Render a select input for array values
      console.log(key, );
      console.log( value,);
      console.log( keyInfo);
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>   
          <Table.Cell>
            <div className="flex flex-row space-x-1">
              <FetchSelector
                selectedMovements={formData[key]}
                settingsUpdate={update}
                itemKey={key}
                url={keyInfo.url}
              />
              <MovementDeSelector
                selectedMovements={formData[key]}
                settingsUpdate={update}
                itemKey={key}
              />
              <ListItems>
                {value.map((item) => (
                  <Badge key={key+item}>
                    {item}
                  </Badge>
                ))}
              </ListItems>
              
            </div>
          </Table.Cell>
        </Table.Row>

      );
    }

    if (keyInfo.type === "number") {
      return(
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label} />
              </Tooltip>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Select className="w-14"
              value={formData[key]}
              onChange={(e) => {
                //handleInputChange(e, key)
                setFormData({
                  ...formData,
                  [key]: parseFloat(e.target.value),
                });
              }}
            >
              {keyInfo.allowedValues.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (keyInfo.type === "bool") {
      return(
        <Table.Row key={key}>
        <Table.Cell>
          <div className="flex flex-row justify-end">
            <Tooltip content={keyInfo.description}>
              <Label value={label} />
            </Tooltip>
          </div>
        </Table.Cell>
        <Table.Cell>

        <div className="flex flex-row space-x-1">
          <ToggleSwitch  className="bg-grey-800" 
            checked={value}
            onChange={(e) => 
              setFormData({
                ...formData,
                [key]: e,
            })}
          />
          <Label value={value? "ON": "OFF"}/>
        </div>
        </Table.Cell>
      </Table.Row>);
    }
/*
    if (inputType === "object") {
      return(
      <Table.Row key={key}>
      <Table.Cell>
        <div className="flex flex-row justify-end">
          <Tooltip content={keyInfo.description}>
            <Label value={label+"object?"} />
          </Tooltip>
        </div>
      </Table.Cell>
      <Table.Cell>
        <TextInput 
          type="text" 
          value={formData[key]}
          onChange={e => handleInputChange(e, key)}
        />
      </Table.Cell>
    </Table.Row>);

    }
*/
    // Render a text input for string values
    if (keyInfo.type === "str") {
      console.log(key, keyInfo);
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <div className="flex flex-row justify-end">
              <Tooltip content={keyInfo.description}>
                <Label value={label+"string?"} />
              </Tooltip>
            </div>
          </Table.Cell>
          <Table.Cell>
            <TextInput 
              type="text" 
              value={formData[key]}
              onChange={e => handleInputChange(e, key)}
            />
          </Table.Cell>
        </Table.Row>
      );
    };
  }
  //const copyMap = deepCopy(settingsMap);
  const copyMap = JSON.parse(JSON.stringify(settingsMap));
  console.log(copyMap);
  if (hideList) {
    for (const key of hideList) {
      delete copyMap[key];
    }
  } else if (showList) {
    for (const key of Object.keys(copyMap)) {
      //console.log(showList);
      //console.log(key);
      if (copyMap[key].type === "recursive") {
        console.log(copyMap[key]);
        for(const innerKey of Object.keys(copyMap[key])){
          if (innerKey === "type") continue;
          const newKey = key + "." + innerKey;
          console.log(newKey);
          if (!showList.includes(newKey)){
            console.log("deleting", newKey);
            delete copyMap[key][innerKey];
          }
          console.log(copyMap[key]);
        }
      } else
      if (!showList.includes(key)){
        delete copyMap[key];
      }
    }
  }

  return (
    <div className="w-full  mx-auto">
      
      
      <Table>
      <Table.Head>
        <Table.HeadCell>
          <div className="flex flex-row justify-end">
            <Tooltip content={"name of your settings"}>
            <Label value={"Settings name"}/>
            </Tooltip>
          </div>
        </Table.HeadCell>
        <Table.HeadCell>
          <TextInput 
            type="text" 
            value={settingsName}
            onChange={e => setSettingsName(e.target.value)}
          />
        </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {
            Object.keys(copyMap).map((key) => {
              //if (formData[key] === undefined)
              //  return "fetching"
              const keyInfo = copyMap[key];
              if (keyInfo.type === "recursive"){
                return Object.keys(copyMap[key]).map(innerKey => {
                  if (innerKey === "type") return undefined;
                  const innerKeyInfo = copyMap[key][innerKey];
                  //console.log(formData[key]);
                  
                  return renderInput(innerKey, formData[key][innerKey], innerKeyInfo);
                });
              }
              console.log(key)
              console.log(formData)
              console.log(keyInfo)
              return renderInput(key, formData[key], keyInfo);
            })
          }
        </Table.Body>
      </Table>
      <Button
        onClick={saveSettings}
      >
        Save
      </Button>
    </div>
  );
}

export default EditGeneratorSettings;