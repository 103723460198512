//import './App.css';
import React, { useReducer, useContext} from "react";
import Login from './login/Login';
import Logout from './logout/Logout';
import UserContext from './usercontext/UserContext';
import AllWodsList from './allwodslist/AllWodsList';
import Tags from './tags/Tags';
import Movements from './movements/Movements';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { WodList } from './wodlist/WodList';
import UsersList from './users/UserList';
import User from './users/User';
import WodDetail from './woddetail/WodDetail';
import WodPartDetail from './woddetail/WodPartDetail';
import Tag from './tags/Tag';
import Movement from './movements/Movement';
import WodPartList from './wodlist/WodPartList';
import WodDetailModify from './woddetail/WodDetailModify';
import RateWodPart from './woddetail/RateWodPart';
import ActivateUser from './users/ActivateUser';
import RegisterUser from './users/RegisterUser';
import ResetPassword from './users/ResetPassWord';
import AppContainer from "./AppContainer/AppContainer";
import ForgotPassword from "./users/ForgotPassword";
import EditGeneratorSettings from "./generatorsettings/EditGeneratorSettigns";
import GeneratorSettingsList from "./generatorsettings/GeneratorSettingsList";
import SettingsManager from "./generatorsettings/SettingsManager";



const initialState = {
  user: "",
  password: "",
  userId: undefined,
  token: {access: "", refresh: ""},
  loggedIn: false,
  refreshing: false,
  userGroups: []
};

const userReducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "login":
        return {
          ...state, 
          user: action.data.user, 
          userId: action.data.userId, 
          token: action.data.token, 
          loggedIn: true
        };
        case "getInfo":
        return {
          ...state, 
          userGroups: action.data.userGroups
        };
    case "setToken":
      return {...state, refreshing: false, token:{ ...state.token, access: action.data.token}};
    case "setRefreshing":
      return {...state, refreshing: action.data.refreshing};
    case "logout":
      return {initialState};
    case "deleteToken":
        return {...state, refreshing: false, token:{ ...state.token, access: ""}};
    default: return state;
  }
}

export function SayHello () {
  const [state, ] = useContext(UserContext);

  return (
    <div>
      Hello {state.user + " id: " + state.userId}
    </div>
  );
}

function PrivateRoute ({children}) {
  const [state, ] = useContext(UserContext);
  const auth = state.userId;
  return auth ? children : <Navigate to="/login" />;
}

function UserAdminroute ({children}) {
  const location = useLocation()

  const [state, ] = useContext(UserContext);
  const auth = state.userId;
  console.log(location);
  //split path:
  const pathParts = location.pathname.split("/");
  console.log(pathParts);
  console.log(pathParts[1],pathParts[2],auth);
  if (pathParts[1] === "wodlist" && parseInt(pathParts[2]) === auth)
    return children;
  const userAdmin = state.userGroups.includes("user_admin")? true: false;
  return auth && userAdmin ? children : <Navigate to="/login" />;
}

function OwnUserPageRoute ({children}) {
  
  const location = useLocation()
  const [state, ] = useContext(UserContext);
  console.log(children);
  console.log(location);
  const auth = state.userId;
  //const self = state.userGroups.includes("user_admin")? true: false;
  return auth && true ? children : <Navigate to="/login" />;
}

function App() {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (<div className="container mx-auto px-4">
    <UserContext.Provider value ={[state, dispatch]}>  
      
      <BrowserRouter>
        <Routes>
          <Route element={<AppContainer/>}>
            <Route path="/mypage/:id" 
              element={
                <OwnUserPageRoute><User/></OwnUserPageRoute>
            }/>
            <Route path="/" 
              element={
                <OwnUserPageRoute><User/></OwnUserPageRoute>
            }/>
            <Route path="/generatorcustomsetting/:id" 
              element={
              <PrivateRoute><EditGeneratorSettings/></PrivateRoute>
            }/>
            <Route path="allwods" 
              element={
              <PrivateRoute><AllWodsList/></PrivateRoute>
            }/>
            <Route path="movements" 
              element={
                <PrivateRoute><Movements/></PrivateRoute>
              }/>
            <Route path="movements/:id" 
              element={
              <PrivateRoute><Movement/></PrivateRoute>
            }/>
            <Route path="tags" 
              element={
              <PrivateRoute><Tags/></PrivateRoute>
            }/>
            <Route path="tags/:id" 
              element={
              <PrivateRoute><Tag/></PrivateRoute>
            }/>
            <Route path="wodlist" 
              element={
              <PrivateRoute><WodList/></PrivateRoute>
            }/>
            <Route path="allwodlist" 
              element={
              <PrivateRoute><WodList/></PrivateRoute>
            }/>
            <Route path="wodlist/:owner" 
              element={
              <UserAdminroute><WodList/></UserAdminroute>
            }/>
            <Route path="wodpartlist" 
              element={
              <PrivateRoute><WodPartList/></PrivateRoute>
            }/>
            <Route path="wodlist/:owner/:completed" 
              element={
              <PrivateRoute><WodList/></PrivateRoute>
            }/>
            <Route path="woddetail/:id" 
              element={
              <PrivateRoute><WodDetail/></PrivateRoute>
            }/>
            <Route path="woddetailmodify/:id" 
              element={
              <PrivateRoute><WodDetailModify/></PrivateRoute>
            }/>
            <Route path="wodpartdetail/:id" 
              element={
              <PrivateRoute><WodPartDetail/></PrivateRoute>
            }/>
            <Route path="users" 
              element={
              <UserAdminroute><UsersList/></UserAdminroute>
            }/>
            <Route path="users/:id" 
              element={
              <UserAdminroute><User/></UserAdminroute>
            }/>
            <Route path="ratewodpart/:id" 
            element={
            <PrivateRoute><RateWodPart/></PrivateRoute>
          }/>
          <Route path="ratewodpart" 
            element={
            <PrivateRoute><RateWodPart/></PrivateRoute>
          }/>
          <Route path="settingslist" 
            element={
            <PrivateRoute><SettingsManager/></PrivateRoute>
          }/>
          </Route>
          <Route path="login" 
            element={
            <Login/>
          }/>
          <Route path="activate/:uid/:token" 
            element={
            <ActivateUser/>
          }/>
          <Route path="passwordreset/:uid/:token" 
            element={
            <ResetPassword/>
          }/>
          <Route path="register" 
            element={
            <RegisterUser/>
          }/>
          <Route path="forgotpassword" 
            element={
            <ForgotPassword/>
          }/> 
          <Route path="/logout" 
            element={
            <PrivateRoute><Logout/></PrivateRoute>
          }/>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
    </div>);
}

export default App;
