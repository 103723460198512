import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { 
   getWholeWod, rateWod, 
  getRandomGeneratedWod,
  apiCallGet,
  apiCallPost
} from "../smartfit_common/apihelper/apihelper";
import { useLocation, useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import { subPartListRenderer } from "../smartfit_common/wodutil/wodutil";
import AddTags from "../tags/AddTags";
import RemoveTags from "../tags/RemoveTags";
import { Button, Badge, Label } from 'flowbite-react'
import GeneratorSettings from "../generatorsettings/GeneratorSettings";
import GeneratorSettingsList from "../generatorsettings/GeneratorSettingsList";
import CreateGeneratorSetting from "../generatorsettings/CreateGeneratorSetting";
import { equipmentSettings, generalSettings, wodSettings, advendSettings, settingTypes,
  createInitialSettingState, settingsMap } from "../generatorsettings/settingConstants";

const settingCombiner = (settingsList) => {
  const combinedSettings = {};
  settingsList.forEach(setting => {
    if (setting && setting.type) {
      for ( const key of settingTypes[setting.type] ) {
        if (key.includes(".")) {
          const subKeys = key.split(".");
          // check if the subkey exists
          if (!Object.keys(combinedSettings).includes(subKeys[0])) {
            combinedSettings[subKeys[0]] = {};
          }
          // check if the value is undefined, null, empty string, or empty array
          if ( setting.settings[subKeys[0]][subKeys[1]] === undefined 
            || setting.settings[subKeys[0]][subKeys[1]] === null 
            || setting.settings[subKeys[0]][subKeys[1]] === "" 
            || (Array.isArray(setting.settings[subKeys[0]][subKeys[1]]) 
            && setting.settings[subKeys[0]][subKeys[1]].length === 0)) {
            continue;
          }
          combinedSettings[subKeys[0]][subKeys[1]] = setting.settings[subKeys[0]][subKeys[1]];
          continue;
        }
        // check if the value is undefined, null, empty string, or empty array
        if ( setting.settings[key] === undefined 
          || setting.settings[key] === null 
          || setting.settings[key] === "" 
          || (Array.isArray(setting.settings[key]) && setting.settings[key].length === 0)) {
          continue;
        }
        // if the key does not exit, or the value is null, undefined, empty string, or empty array, just add the key and value
        if (!Object.keys(combinedSettings).includes(key)
          || combinedSettings[key] === undefined 
          || combinedSettings[key] === null
          || combinedSettings[key] === ""
          || (Array.isArray(combinedSettings[key]) && combinedSettings[key].length === 0)) {
          combinedSettings[key] = setting.settings[key];
          continue;
        }
      
        // if the value is an array, combine the two arrays
        if (Array.isArray(combinedSettings[key])) {
          combinedSettings[key] = [...combinedSettings[key], ...setting.settings[key]];
          continue;
        } 
        // if the value is an object, combine the two objects
        if (typeof combinedSettings[key] === "object") {
          combinedSettings[key] = {...combinedSettings[key], ...setting.settings[key]};
        }
      }
    }
  });
  console.log(combinedSettings);
  const defaultSettings = createInitialSettingState(settingsMap);
  for (const key of Object.keys(defaultSettings)) {
    if (Object.keys(combinedSettings).includes(key)) {
      defaultSettings[key] = combinedSettings[key];
    }
  }
  console.log(defaultSettings);
  return defaultSettings;
};


function RateWodPart () {
  const [selectedEquipmentSetting, setSelectedEquipmentSetting] = useState(null);
  const [selectedGeneralSetting, setSelectedGeneralSetting] = useState(null);
  const [selectedWodSetting, setSelectedWodSetting] = useState(null);
  const [tagList, setTagList] = useState([]);

  const [rating, setRating] = useState(0);
  const [state, dispatch] = useContext(UserContext);
  const [prevWodsList, setPrevWodsList] = useState([]);
  //const location = useLocation();
  //const { id } = useParams();
  //if (!id) console.log("no id in params")
  //const [wod, setWod] = useState(location.state? location.state.wod.wod: {id: id, description: "", name: "", result: ""});
  const [wod, setWod] = useState( {id: undefined, description: "", name: "", result: ""});

  const [wodResultText, setWodResultText] = useState(wod.result);
  //const myWodPartList = subPartListRenderer(wod);
  const [myWodPartList, setMyWodPartList] = useState([]);
  //console.log(prevWodsList)
  console.log(selectedEquipmentSetting);
  //console.log(wod);
  console.log("new rating: " + rating);

  const getNewRandomWod = () => {

    setRating(0);
    console.log(selectedEquipmentSetting);
    console.log(JSON.stringify(selectedEquipmentSetting.settings));
    const combinedSettings = settingCombiner([selectedEquipmentSetting, 
      selectedGeneralSetting, selectedWodSetting]);
    apiCallPost(state, dispatch, undefined, '/get_random_wod/', {
      //custom_settings: selectedEquipmentSetting.id,
      settings_str: JSON.stringify(combinedSettings),
      //settings_list: [selectedEquipmentSetting, selectedGeneralSetting, selectedWodSetting],
    }).then((result) =>{
    //getRandomGeneratedWod(state, dispatch).then(result => {
      console.log(result);
        setWod(result.data);
        setMyWodPartList(subPartListRenderer(result.data.child));
        prevWodsList.push(wod.id)
        setPrevWodsList(prevWodsList)
      }).catch(e => console.log(e.message));
  }

  const getPreviousWod = () => {
    
   getWholeWod(state, dispatch, undefined, wod.id).then((result) =>{
      //setMyWod(wodPartListRenderer(result));
      getWholeWod(state, dispatch, wod.id).then((result) =>{
        //setMyWod(wodPartListRenderer(result));
        console.log(result);
        setTagList(result.tags);
        //console.log(myWodList[2]);
      });
      //setTagList(result.tags);
      //console.log(myWodList[2]);
    });
  }

  const listItems = myWodPartList.map(wod => {
    return (
      <li key={wod.id.toString()}>
        {wod.text}
      </li>
    );
  });

  const refreshTagList = () => {
    getWholeWod(state, dispatch, wod.id).then((result) =>{
      //setMyWod(wodPartListRenderer(result));
      console.log(result);
      setTagList(result.tags);
      //console.log(myWodList[2]);
    });
  }
  const saveRating =(saveRating) => {
    
    rateWod(state, dispatch, undefined, wod.id, saveRating).then(result => { //TODO Rating has to be improved
      // also rate parent wod, since it is only wrapper for generated wodpart
      rateWod(state, dispatch, undefined, wod.parent_id, saveRating).then(res => {
        console.log(res);
        setRating(saveRating);
      }).catch(err => console.log(err.message));
    }).catch(e => console.log(e.message));
  }


  return (
    <div>
      <h1 className="text-lg font-bold">
        {wod.name + " " + wod.description}
      </h1>
      <br/>
      <ul>
        {listItems}  
      </ul> 
      <br/>
      <div className="flex flex-row space-x-2">
        <div className="flex flex-col space-y-2 0">
          <div className="rounded">
          <div className={rating === 0? "rounded bg-gray-400 text-gray-50": (rating === 1?"rounded bg-green-500 text-gray-50": "rounded bg-red-600 text-gray-50")}>
            <div className="flex flex-row justify-center font-mono subpixel-antialiased font-bold">
              {rating === 0? "good / bad": rating === 1? "GOOD!": "BAD!"}
            </div>
          </div>
          </div>
          <div className="flex flex-row space-x-2">
              <Button className="bg-green-400"
                onClick={() => {
                  if (rating === 1) {
                    saveRating(0);
                  } else {
                    saveRating(1);
                  } 
                }}
              >
            <div className=" w-20 ">
                Good
            </div>
              </Button>
              <Button className="bg-red-500"
                onClick={() => {
                  if (rating === -1) {
                    setRating(0);
                  } else {
                    saveRating(-1);
                  }
                }}
              >
            <div className=" w-20 ">
                Bad
            </div>
              </Button>
          </div>
          <div className="flex flex-row space-x-2">
              {/* Disable Previous button for the time
          <Button
            onClick={getPreviousWod}
          >
            <div className=" w-20 flex flex-row justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
              WOD
            </div>
          </Button>
              */ }
          <Button
            onClick={getNewRandomWod}
          >
            <div className=" w-20 flex flex-row justify-center text-">
              WOD
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
            </div>
          </Button>
          </div>
        </div>
        <div className="flex flex-row space-x-2">
          <div  className="flex flex-col space-y-2 0">
            <Label value="Equipment Setting"/>
            <GeneratorSettingsList
              type={"equipment"}
              selectedSetting={selectedEquipmentSetting}
              setSelectedSetting={setSelectedEquipmentSetting}
              showRadioButtons={true}
            />
          </div>
          <div  className="flex flex-col space-y-2 0">
            <Label value="General Setting"/>
            <GeneratorSettingsList
              type={"general"}
              selectedSetting={selectedGeneralSetting}
              setSelectedSetting={setSelectedGeneralSetting}
              showRadioButtons={true}
            />
          </div>
          <div  className="flex flex-col space-y-2 0">
            <Label value="Workout Setting"/>
            <GeneratorSettingsList
              type={"workout"}
              selectedSetting={selectedWodSetting}
              setSelectedSetting={setSelectedWodSetting}
              showRadioButtons={true}
            />
          </div>
        </div>
      </div>
      
      
      {/* 
      <AddTags
        relatedId={wod.id} 
        refreshTagList={refreshTagList} 
        addTagCallback={addTagToWod}
      />
      <RemoveTags
        relatedId={wod.id}
        refreshTagList={refreshTagList}
        tagList={tagList}
        removeTagCallback={removeTagFromWod}
      />
      */}
    </div>
  );
}
export default RateWodPart;