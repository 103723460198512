import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { setUserIsActive} from "../smartfit_common/apihelper/apihelper";
import {Button} from 'flowbite-react'

function DeactivateUser ({id, update, isActive}) {
  const [state, dispatch] = useContext(UserContext);
  const [confirm, setConfirm] = useState(false);

  const handleDelete = () => {
    setUserIsActive(state, dispatch, id, !isActive).then(() => {
      setConfirm(false);
      update();
    }).catch(error => console.log(error.message));
  }
  const confirmDelete = () => {
      setConfirm(true);
  }
  const cancelDelete = () => {
      setConfirm(false);
  }
  
  return (<>
    {
      !confirm && (
        <Button
          onClick={confirmDelete}
        >
          {isActive&&"deactivate"||"activate"}
        </Button>
      )
    }
    {
      confirm && (
        <>
          confirm  {"-->"}
          <Button
            onClick={cancelDelete}
          >
           cancel
          </Button>
          <Button
            onClick={handleDelete}
          >
            {isActive&&"deactivate"||"activate"}
          </Button>
        </>
      )
    }
    </>
  );
}
export default DeactivateUser;