import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getAllTags, getAllMovements, 
  getTag, getMovement, addTagToMovement,removeTagFromMovement, updateMovement 
} from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";

import AddTags from "../tags/AddTags";
import RemoveTag from "../tags/RemoveTag";
import RemoveTags from "../tags/RemoveTags";
import { isAdminuser, isWodAdmin } from "../usercontext/UserContext";
import {Button, Card, TextInput, Textarea} from 'flowbite-react'
import UpdateMovement from "./UpdateMovement";


function Movement () {
  const [state, dispatch] = useContext(UserContext);
  const [tagList, setTagList] = useState([]);
  const [page, setPage] = useState(1);
  const [movementName, setMovementName] = useState("");
  const [maxPage, setMaxPage] = useState(1);
  const { id } = useParams();
  //const wodAdmin = isAdminuser(state);
  const wodAdmin = isWodAdmin(state);
  const [changeVideo, setChangeVideo] = useState(false);
  const [newVideoLink, setNewVideoLink] = useState("");
  const [changeText, setChangeText] = useState(false);
  const [movementText, setMovementText] = useState("");
  const [oldText, setOldText] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    getMovement(state, dispatch, undefined, id).then(result => {
      console.log(result);
      setTagList(result.tags);
      setMovementName(result.name);
      console.log(result);
      setNewVideoLink(result.video_link);
      setMovementText(result.text);
      setOldText(result.text);
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }, [dispatch, id, state]);

  const update = () => {
    getMovement(state, dispatch, undefined, id).then(result => {
      console.log(result);
      setTagList(result.tags);
      setMovementName(result.name);
      console.log(result);
      setNewVideoLink(result.video_link);
      setMovementText(result.text);
      setOldText(result.text);
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
        console.log("navigate")
        navigate("/movements");

    });
  }

  const refreshTagList = () => {
    getMovement(state, dispatch, undefined, id).then(result => {
        console.log(result);
      setTagList(result.tags);   
      //console.log(result);
    }).catch(error => {
      console.log(error.message);
    });
  }
  
  function videoChangeHandler() {
    updateMovement(state, dispatch, undefined, id, {video_link: newVideoLink})
      .then(result => {
        setChangeVideo(false);
      })
      .catch(error => console.log(error.message));
    
  }

  function textChangeHandler() {
    let sendText = "no description";
    if (movementText.replace(/\s/g, '').length) {
      sendText = movementText
    }
    
    updateMovement(state, dispatch, undefined, id, {text: sendText})
      .then(result => {
        setChangeText(false);
      })
      .catch(error => console.log(error.message));
  }



  return (
    <div className="flex flex-col space-y2">
      <div className="flex flex-row space-x-4">
        <div className="flex flex-col space-y-2">
          <h3 class="text-3xl font-bold dark:text-white">
            {movementName}
            {wodAdmin &&
              <UpdateMovement 
                  update={update} 
                  moveId={id} 
                  moveName={movementName}
                  hideName={true}
                  hideButtons={false}
                />
            }   
          </h3>

          <Link to={"/wodpartlist/"} state={{movement: id, movementName: movementName}}>
            <h4 class="text-2xl font-bold dark:text-white">
              <p class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                Show related Workouts
              </p>
            </h4>
            </Link>
            <div className="flex flex-row space-x-2">
              {newVideoLink === "" &&
                <div>{"add Video Link"}</div>
              }
              {!changeVideo && newVideoLink !== "" &&
                <a 
                  href={newVideoLink} 
                  class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Video
                </a>
              }{changeVideo && wodAdmin &&
                <TextInput
                  type={"text"}
                  value={newVideoLink}
                  onChange={e => setNewVideoLink(e.target.value)}
                />
              }
              {wodAdmin && !changeVideo &&
                <Button 
                  size={"xs"}
                  onClick={() => setChangeVideo(true)}
                >
                  edit
                </Button>
              }{wodAdmin && changeVideo &&
                <div className="flex flex-row space-x-2">
                  <Button
                    size={"xs"}
                    onClick={videoChangeHandler}
                  >
                    save
                  </Button>
                  <Button
                    size={"xs"}
                    onClick={() => setChangeVideo(false)}
                  >
                    cancel
                  </Button>
                </div>
              }
            </div>
          
        </div>
        {(wodAdmin || movementText !== "no description") &&
          <Card>
            {wodAdmin && changeText &&
              <Textarea
              value={movementText}
              onChange={e => setMovementText(e.target.value)}
              />
            }
            {!changeText && 
              <p>{movementText}</p>
            }
            {wodAdmin && !changeText &&
              <Button
              size={"xs"}
              onClick={() => setChangeText(true)}
              >
                edit
              </Button>
            }
            {wodAdmin && changeText &&
              <div className="flex flex-row space-x-2">
                <Button
                size={"xs"}
                onClick={textChangeHandler}
                >
                  save
                </Button>
                <Button
                size={"xs"}
                onClick={() => {
                  setChangeText(false);
                  setMovementText(oldText);
                }}
                >
                  cancel
                </Button>
              </div>
            }
          </Card>
        }
        <div className="flex flex-col space-y-2">
          <div className="flex justify-center">
            <h4 class="text-2xl font-bold dark:text-white">Tags</h4>
          </div>
          <RemoveTags
            relatedId={id}
            refreshTagList={refreshTagList}
            tagList={tagList}
            removeTagCallback={removeTagFromMovement}
          />
        </div>
      </div>
      <div className="flex flex-col space-y2">
        <h4 class="text-2xl font-bold dark:text-white">Add Tags</h4>
          <AddTags 
            relatedId={id} 
            refreshTagList={refreshTagList} 
            addTagCallback={addTagToMovement}
          />
      </div>
    </div>
  );
}
export default Movement;