import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { saveTag, deleteTag} from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import {Button} from 'flowbite-react'

function DeleteTag (props) {
  const [state, dispatch] = useContext(UserContext);
  const [confirm, setConfirm] = useState(false);

  const handleDelete = () => {
    deleteTag(state, dispatch, undefined, props.tagId).then(() => {
      props.update();
    }).catch(error => console.log(error.message));
  }
  const confirmDelete = () => {
      setConfirm(true);
  }
  const cancelDelete = () => {
      setConfirm(false);
  }
  
  return (<>
    {
      !confirm && (
        <Button
          onClick={confirmDelete}
        >
          del
        </Button>
      )
    }
    {
      confirm && (
        <>
          cofirm delete {" ------> "}
          <Button
            onClick={cancelDelete}
          >
           cancel
          </Button>
          <Button
            onClick={handleDelete}
          >
            D E L
          </Button>
        </>
      )
    }
    </>
  );
}
export default DeleteTag;