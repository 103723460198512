import {getOwnUserInfo, getToken, refreshToken, requestPasswordReset,
  getUsernameByEmail,
  getUser} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext } from "react";
import UserContext from '../usercontext/UserContext';
import NavBar from '../navbar/NavBar';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const [state, dispatch] = useContext(UserContext);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("enter Email Adress here");
  const [loginBy, setLoginBy] = useState("Username");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    console.log(loginBy)
    if (loginBy === "Email"){
      console.log(user);
      getUsernameByEmail(user).then((res) => {
        console.log(res);
        if(res.status === 200)
          callLogin(res.data.username);
      }).catch(e => console.log(e.message))
      return;
    }
    callLogin(user);
  }

  const callLogin = (username) => {
    console.log("login " +  user);
    getToken(username, password).then(token => {
      //TODO how to get user ID (token required)
      
      getOwnUserInfo(undefined, token.access).then(response => {
        dispatch({
          type: "login", 
          data: {
            user: user, 
            userId: response.id, 
            token: token
          }
        });
        getUser(state, response.id, token.access).then((res) => {
          console.log(res.user_groups);
          dispatch({
            type: "getInfo",
            data: {
              userGroups: res.user_groups
            }
          })
        }).catch(e => console.log(e));
        
      }).catch(error => console.log(error));
      
      setTimeout(navigate, 1000, "/"

      ); //navigate("/");
    }).catch(error => {
      console.log(error.message);
      if (error.response.status === 401) {
        setErrorMessage("Username or Password wrong");
      } else {
        setErrorMessage(error.message);
      }
    });
  }

  const handleReset = () => {
    requestPasswordReset(email).then(res => {
      setErrorMessage("reset Email has been sent to " + email);
    }).catch(e => console.log(e.message));
  }
/*
  return (
    <div>
      <h1>Login</h1>
        <h3>By {loginBy}</h3>
        <input 
          type="radio" 
          id="username" 
          name="loginby" 
          value="Username"
          checked={loginBy === "Username"}
          onChange={e => setLoginBy(e.target.value)}
        />
        <label htmlFor="username">Username</label>
      <input 
        type="radio" 
        id="email" 
        name="loginby" 
        value="Email"
        checked={loginBy === "Email"}
        onChange={e => setLoginBy(e.target.value)}
      />
      <label htmlFor="email">email</label>

        <br/>
        <label> 
          <span class="block text-sm font-medium text-slate-700">{loginBy}:</span>
            <input class="mt-1 block px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              type="text" 
              value={user}
              onChange={e => setUser(e.target.value)}
            />
        </label>
        <br/>
        <label>
        <span class="block text-sm font-medium text-slate-700">Password:</span> 
          <input class="mt-1 block px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 "
            type="text" 
            value={password}
            onChange={e => setPassword(e.target.value)} 
          />
        </label>
        <br/>
        <button class="bg-sky-500 hover:bg-sky-700 ..."
          onClick={handleLogin}
        >
          Login
        </button> 

      <h4>{errorMessage}</h4>
      <label>Forgot Password? </label>
      <input class="mt-1 block px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        type="text"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <button class="bg-sky-500 hover:bg-sky-700 ..."
      onClick={()=>{
        handleReset();
      }}
      >
        Forgot Password
      </button>
      <br/>
      <NavBar/>
    </div>
  );*/
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            {/*<p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
              </a>
            </p>*/}
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST"
            onSubmit={handleLogin}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label className="sr-only">
                  username
                </label>
                <input
                  value={user}
                  onChange={e => setUser(e.target.value)} 
                  type="username"
                  autoComplete="username"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="User Name"
                />
              </div>
              <div>
                <label className="sr-only">
                  Password
                </label>
                <input

                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)} 
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/*
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>
                */}

              <div className="text-sm">
                <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Register
                </Link>
              </div>
              <div className="text-sm">
                <Link to="/forgotpassword" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login;