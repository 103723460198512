import React, { 
  useState, 
  //useContext, 
  //useEffect 
} from "react";
//import UserContext from '../usercontext/UserContext';
import { Badge, Button } from 'flowbite-react'
import {
  Table, 
  Label, 
} from 'flowbite-react'




function MovementDeSelector ({selectedMovements, settingsUpdate, itemKey, index}) {

  const [showTable, setShowTable] = useState(false);

  function removeMovement(movementName) {
    if (index !== undefined) {
      selectedMovements.splice(selectedMovements.indexOf(movementName), 1);
      settingsUpdate(selectedMovements, itemKey, index);
      return;
    }
    selectedMovements.splice(selectedMovements.indexOf(movementName), 1);
    settingsUpdate(selectedMovements, itemKey);
  }

  const listTableItems = selectedMovements.map((movement, i) => {

    return (
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={i}>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" >
         
        <Badge>
          {movement}
        </Badge>
        </Table.Cell>

        <Table.Cell>
          <div className="flex justify-end">
          
          <Button
            size={"xs"}
            onClick={
              () => removeMovement(movement)
            }
          >
            del
          </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  });
  

  return (<>
    {!showTable && 
      <Button
        onClick={() => setShowTable(true)}
        size={"xs"}
      >
        del
      </Button> 
    }
    <div className="flex flex-col space-y-3">

    {showTable && 
      <Button
        onClick={() => setShowTable(false)}
        >
        Done
      </Button> 
    }
    {showTable && 
      <div>
        <div>
          <div className="mb-2 block">
            <Label
              value="Filter:"
              />
          </div>

        </div>

        <Table striped={true} hoverable={true}>
          <Table.Head>
            <Table.HeadCell>Tag</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
          {listTableItems}
        </Table.Body>
      </Table>

      </div>
    }
    </div>
  </>
  );
}
export default MovementDeSelector;