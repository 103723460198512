import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getWholeWod, addTagToWod, removeTagFromWod 
} from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { useLocation, useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import { wodPartListRenderer } from "../smartfit_common/wodutil/wodutil";
import WodPreview from "./WodPreview";
import WodPartDetail from "./WodPartDetail";
import DeleteWod from "./DeleteWod";
import AddTags from "../tags/AddTags";
import RemoveTag from "../tags/RemoveTag";
import RemoveTags from "../tags/RemoveTags";


function WodDetail () {
  const [state, dispatch] = useContext(UserContext);
  const [myWod, setMyWod] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [wodName, setWodName] = useState("");
  const { id } = useParams();
  const location = useLocation();
  console.log(id);
  useEffect(() => {
    getWholeWod(state, dispatch, undefined, id).then((result) =>{
      setMyWod(wodPartListRenderer(result.child));
      setWodName(result.name);
      //console.log(myWodList[2]);
    });
  }, [state, dispatch, id]);

  const refreshTagList = () => {
    getWholeWod(state, dispatch, undefined, id).then((result) =>{
      //setMyWod(wodPartListRenderer(result));
      console.log(result);
      setTagList(result.tags);
      //console.log(myWodList[2]);
    });
  }

  const listItems = myWod.map(wod => {
    return (
      <li key={wod.id.toString()}>
        <h4>
          <Link to={"/wodpartdetail/"+wod.id} state={{wod: wod}}>
            {wod.name + " " + wod.description}
          </Link>
        </h4>
        {(wod.wod.is_completed && ("completed")) || ("not completed")}
        <WodPreview 
          wod={wod.wod}
          showDeleteButton={"false"}
          showEditButton={"true"}
          showSpaceButton={"true"}
        />
      </li>
    );
  });
  
  return (
    <div>
      <h1>{location.state.wodName || wodName}</h1>
      <h2>Tags</h2>
      <RemoveTags
        relatedId={id}
        refreshTagList={refreshTagList}
        tagList={tagList}
        removeTagCallback={removeTagFromWod}
      />

      <h2>Add Tags</h2>
      <AddTags
        refreshTagList={refreshTagList}
        addTagCallback={addTagToWod}
        relatedId={id}
       />
      <ul>
        {listItems}  
      </ul>
      <DeleteWod 
        wodId={id}
        owner={location.state.owner}
        completed={location.state.completed}
        page={location.state.page}
        isLast={location.state.isLast}
      />
    </div>
  );
}
export default WodDetail;