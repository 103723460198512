import React, { useState, useContext, useEffect } from "react";
import { Modal, TextInput, Label, Button, Checkbox, Dropdown } from "flowbite-react";
import GeneratorSettingsList from "./GeneratorSettingsList";

function SettingsManager () {
    return (
        <div className="flex flex-row space-x-2">
            <div className="flex flex-col space-y1">
                <Label value="Equipment Setting"/>
                <GeneratorSettingsList
                    showOptions={true}
                    showCreateButton={true}
                    createButtonLabel={"Create new equipment setting"}
                    type={"equipment"}
                />
            </div>
            <div className="flex flex-col space-y1">
                <Label value="General Settings"/>
                <GeneratorSettingsList
                    showOptions={true}
                    showCreateButton={true}
                    createButtonLabel={"Create new general setting"}
                    type={"general"}
                />
            </div>
            <div className="flex flex-col space-y1">
                <Label value="Workout Settings"/>
                <GeneratorSettingsList
                    showOptions={true}
                    type={"workout"}
                    showCreateButton={true}
                    createButtonLabel={"Create new workout setting"}
                />
            </div>
        </div>
    );
}
export default SettingsManager;