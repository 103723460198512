import { requestPasswordReset,} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext } from "react";
import UserContext from '../usercontext/UserContext';
import NavBar from '../navbar/NavBar';
import {  useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react'


function ForgotPassword() {
  const [state, dispatch] = useContext(UserContext);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("enter Email Adress here");
  const [loginBy, setLoginBy] = useState("Username");
  const navigate = useNavigate();



  const handleReset = () => {
    requestPasswordReset(email).then(res => {
      setErrorMessage("reset Email has been sent to " + email);
    }).catch(e => console.log(e.message));
  }

  return (
    <div>
      <h1 className="text-lg font-bold">Password Reset</h1>
        
      <label>Forgot Password? </label>
      <input class="mt-1 block px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        type="text"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button 
        onClick={()=>{
          handleReset();
        }}
      >
        Forgot Password
      </Button>
      <h4>{errorMessage}</h4>
      <br/>
      <NavBar/>
    </div>
  );
}

export default
  ForgotPassword