import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { getWholeWod, saveWodResult, saveWod} from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { useLocation, useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import { subPartListRenderer } from "../smartfit_common/wodutil/wodutil";
import {Textarea, Button} from 'flowbite-react'

function EnterWod ({userId, closeCallback}) {
  const [state, dispatch] = useContext(UserContext);
  //const location = useLocation();
  const { wodId } = useParams();
  //console.log(location);
  console.log(userId);

  
  const [wodText, setWodText] = useState("#title WorkoutName xy\nA: description\n");

  return (
    <div>
      <h4 class="text-xl font-bold dark:text-white">Enter Workout</h4>
      <div className="flex flex-col space-y-2">
        <Textarea
          value={wodText}
          onChange={(e) => {
            setWodText(e.target.value);
          }}
          rows={10}
          spellCheck={false}
          />
        <div className="flex flex-row space-x-1">
          <Button
            onClick={() => {
              saveWod(state, dispatch, wodText, userId);
              setWodText("");
            }}
            >
            save
          </Button>
          <Button
            onClick={() => closeCallback()}
            >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
export default EnterWod;