import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';

import { 
  apiCallDelete,
  apiCallGet,
  apiCallPatch,
  apiCallPost
} from "../smartfit_common/apihelper/apihelper";
import { useLocation, useParams } from "react-router-dom";
import {Link} from 'react-router-dom';
import { subPartListRenderer } from "../smartfit_common/wodutil/wodutil";
import AddTags from "../tags/AddTags";
import RemoveTags from "../tags/RemoveTags";
import { Button, Badge, Dropdown, Radio, } from 'flowbite-react'
import CreateGeneratorSetting from "./CreateGeneratorSetting";
import ConfirmModal from "../tags/ConfirmModal";
import DropDownConfirmModal from "./DropDownConfirmModal";
import RenameModal from "./RenameModal";
import { equipmentSettings, generalSettings, wodSettings, advendSettings } from "./settingConstants";


const GeneratorSettingsList = ({selectedSetting, setSelectedSetting, showOptions, showRadioButtons,
                                  showCreateButton, createButtonLabel, type}) => {
  //const [selectedSetting, setSelectedSetting] = useState(null);
  const [settings, setSettings] = useState([]);
  const [state, dispatch] = useContext(UserContext);
  //console.log(state)
  useEffect(() => {
    apiCallGet(state, dispatch, undefined, 
      "/getcustomsettings/",
      {
        type: type
      }
    ).then(res => {
      console.log(res)
      setSettings(res);
    }).catch(e => console.log(e.message));
  }, [dispatch, state, type]);

  const update = () => {
    apiCallGet(state, dispatch, undefined, 
      "/getcustomsettings/",
      {
        type: type
      }
    ).then(res => {
      console.log(res)
      setSettings(res);
    }).catch(e => console.log(e.message));
  }

  const handleSelectSetting = (id) => {
    setSelectedSetting(id);
    console.log(id);
  };

  const handleModalDublicate = (close, id) => {
    apiCallGet(state, dispatch, undefined, "/getcustomsettings/", {id: id}).then(res => {
      console.log(res);
      const copySetting = {... res};
      copySetting.name = "copy of " + res.name;
      apiCallPost(state, dispatch, undefined, 
        "/addcustomsettings/",
        {
          name: copySetting.name,
          public: copySetting.public,
          type: copySetting.type,
          settings: JSON.stringify(copySetting.settings),
        }
      ).then(result => {
        console.log(result)
        update();
        close();

      }).catch(err => console.log(err.message));
    }).catch(e => console.log(e.message));
  };

  const handleShare = (/*close, */id) => {
    apiCallPatch(state, dispatch, undefined, 
      "/changecustomsettings/", {id: id, public: true}).then(res => {
        console.log(res);
        update();
        //close();
    }).catch(e => console.log(e.message));  };

  const handleRename = (close, id, newName) => {
    apiCallPatch(state, dispatch, undefined, 
      "/changecustomsettings/", {id: id, name: newName}).then(res => {
        console.log(res);
        update();
        close();
    }).catch(e => console.log(e.message));
  };

  const handleDelete = (close, id) => {
    apiCallDelete(state, dispatch, undefined, 
      "/delcustomsettings/", {id: id}).then(res => {
        console.log(res);
        update();
        close();
    }).catch(e => console.log(e.message));
  };

  const displaySetting = {showList: []};
  if (type === "equipment") {
    displaySetting.showList = equipmentSettings;
  } else if (type === "general") {
    displaySetting.showList = generalSettings;
  } else if (type === "workout") {
    displaySetting.showList = wodSettings;
  } else if (type === "advanced") {
    displaySetting.showList = advendSettings;
  }

  return (
    <div  className="flex flex-col space-y-2 0">
      {showCreateButton&&
        <CreateGeneratorSetting 
          update={update} 
          buttonLabel={createButtonLabel}
          type={type}
        />
      }
      <fieldset id={type}>
      <div className="flex flex-col ">
        {settings.map((setting) => (
          <label
            key={setting.id}
            className=" px-4 py-3 bg-white rounded-lg shadow-md cursor-pointer transition-all duration-200 hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <div className="flex justify-between space-x-2">
              <div className="text-lg font-medium">
                
                <Link to={"/generatorcustomsetting/"+setting.id} state={displaySetting} >
                  <p class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                   {setting.name  + (setting.public? " (public)": "")}
                  </p>
                </Link>
                
              </div>
              <div className="flex flex-row justify-end space-x-2"> 
                {showOptions&&
                  <Dropdown
                    label="Options"
                    dismissOnClick={false}
                    size={"xs"}
                  >
                    <DropDownConfirmModal
                      buttonText={"Delete"}
                      confirmText={"delete " + setting.name + "?"}
                      onConfirm={(close) => handleDelete(close, setting.id)}
                      color={"failure"}
                    />
                    <DropDownConfirmModal
                      buttonText={"Dublicate"}
                      confirmText={"Dublicate " + setting.name + "?"}
                      onConfirm={(close) => handleModalDublicate(close, setting.id)}
                    />
                    
                    <Dropdown.Item 
                      onClick={(/*close*/) => handleShare(/*close, */setting.id)}
                    >
                        Share
                    </Dropdown.Item>
                    <RenameModal
                      buttonText={"Rename"}
                      oldName={setting.name}
                      title={"Change Setting Name"}
                      onConfirm={(close, newName) => handleRename(close, setting.id, newName)}
                    />

                  </Dropdown>
                }
                {showRadioButtons&&
                  <Radio
                    name={type}
                    id={setting.id}
                    value={setting}
                    checked={selectedSetting === setting}
                    onChange={() => handleSelectSetting(setting)}
                    onClick={() => console.log("clicked")}
                    className="form-radio h-5 w-5 text-blue-500 transition duration-150 ease-in-out"
                  />
                }
              </div>
            </div>
          </label>
        ))}
      </div>
      </fieldset>
      
    </div>
  );
};

export default GeneratorSettingsList;