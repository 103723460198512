import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { saveTag } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { Button, TextInput, Label } from 'flowbite-react'


function EnterTag (props) {
  const [state, dispatch] = useContext(UserContext);
  const [tag, setTag] = useState("");

  const handleSave = () => {
    saveTag(state, dispatch, undefined, tag).then(() => {
      console.log("tag saved " + tag);
      props.update();
      setTag("");
    }).catch((error) => {
      console.log(error.message);
    });
  }
  
  return (
<div className='flex flex-row space-x-2 items-end '>
      <div>
        <Label value={"Enter new Tag"}/>
        <TextInput 
          type="text" 
          value={tag}
          onChange={e => setTag(e.target.value)}
        />
      </div>
      <Button
        onClick={handleSave}
      >
        save
      </Button>
    </div>

  );
}
export default EnterTag;