import {getToken} from '../smartfit_common/authmanager/AuthManager';
import React, { useState, useContext, useEffect } from "react";
import UserContext from '../usercontext/UserContext';
import { deleteMovement } from "../smartfit_common/apihelper/apihelper";
import NavBar from '../navbar/NavBar';
import { Button } from 'flowbite-react'

function DeleteMovement (props) {
  const [state, dispatch] = useContext(UserContext);
  const [confirm, setConfirm] = useState(false);

  const handleDelete = () => {
    deleteMovement(state, dispatch, undefined, props.moveId).then(() => {
      props.update();
    }).catch(error => console.log(error.message));
  }
  const confirmDelete = () => {
      setConfirm(true);
  }
  const cancelDelete = () => {
      setConfirm(false);
  }
  
  return (<div class="flex flex-row ...">
    {
      !confirm && (
        <div>
          <Button
            size={"xs"}
            onClick={confirmDelete}
            >
            del
          </Button>
        </div>
      )
    }
    {
      confirm && (
        <>
          cofirm delete {" ------> "}
          <div>
            <Button
              onClick={cancelDelete}
              size={"xs"}
              >
            cancel
            </Button>
          </div>
          <div>
            <Button
              onClick={handleDelete}
              size={"xs"}
              >
              D E L
            </Button>
          </div>
        </>
      )
    }
    </div>
  );
}
export default DeleteMovement;