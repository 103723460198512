export const settingsMap = {
  "wod_name": {
    type: "str",
    label: "Workout Name",
    default: "my Workout",
    description: "Name of the generated workout",
  },
  "wod_description": {
    type: "str",
    label: "Description",
    default: "",
    description: "Description of the generated workout",
  },
  "max_number_of_movements": {
    type: "number",
    label: "Maximum Number of Movements",
    default: 3,
    selectOne: true,
    selectMany: false,
    description: "how many different movements",
    allowedValues: [1,2,3,4,5],
  },
  "min_number_of_movements": {
    type: "number",
    label: "Minimum Number of Movements",
    default: 2,
    description: "at least how many movements",
    selectOne: true,
    selectMany: false,
    allowedValues: [1,2,3,4,5],
  },
  "min_number_of_movements_for_time": {
    type: "number",
    label: "Minimum Number of Movements in 'FOR TIME' Workout",
    default: 2,
    description: "at least how many movements in for time wod",
    selectOne: true,
    selectMany: false,
    allowedValues: [1,2,3,4,5],
  },
  "min_wod_rounds": {
    type: "number",
    label: "Minimum Number of Rounds in Workout",
    default: 3,
    description: "how many rounds at least",
    selectOne: true,
    selectMany: false,
    allowedValues: [1,2,3,4,5],
  },
  "max_wod_rounds": {
    type: "number",
    label: "Max Number of Rounds in Workout",
    default: 10,
    description: "how many rounds max",
    selectOne: true,
    selectMany: false,
    allowedValues: [1,2,3,4,5,6,7,8,9,10],
  },
  
  "min_number_of_intervals": {
    type: "number",
    label: "min Number of Intervals in Workout",
    default: 2,
    description: "how many intervals at least",
    selectOne: true,
    selectMany: false,
    allowedValues: [1,2,3,4,5],
  },
  "max_number_of_intervals": {
    type: "number",
    label: "Max Number of Intervals in Workout",
    default: 5,
    description: "how many intervals max",
    selectOne: true,
    selectMany: false,
    allowedValues: [1,2,3,4,5],
  },
  "wod_modes": {
    type: "str list",
    label: "Select kinds of Workouts",
    description: "Select allowed types of Workouts",
    default: [],
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "ROUNDS FOR TIME",
      "AMRAP", 
      "ROUNDS", 
      "FOR TIME", 
      "EMOM",
    ]
  },
  "target_time_min": {
    type: "number list",
    label: "Select Timeframe min/max for Workout duration",
    default: [10, 15],
    description: "chose time range in minutes. E.g. 10 and 20",
    selectOne: false,
    selectMany: true,
    allowedValues: [5,6,7,8,9,10,12,15,20,25,30,35,40,45,50,55,60],
    numberOfValues: 2
  },
      
  "white_list_movements": {
    type: "list movement",
    label: "Movement White List",
    default: [],
    description: "select movements to whitelist",
    url: "/movements/",
    selectOne: false,
    selectMany: true,
  },
  "black_list_movements": {
    type: "list movement",
    label: "Movement Black List",
    default: [],
    description: "select movements to blacklist",
    url: "/movements/",
    selectOne: false,
    selectMany: true,
  },
  "must_include_movements": {
    type: "list movement",
    label: "Mandatory Movement(s)",
    default: [],
    description: "select movements that have to be in the workout",
    url: "/movements/",
    selectOne: false,
    selectMany: true,
  },
  "white_list_equipment": {
    type: "str list",
    label: "Equipment White List",
    default: [],
    description: "Select allowed types equipment",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "Kettlebell",
      "Dumb Bell", 
      "Barbell",
      "Pullup Bar", 
      "Rings", 
      "Rope",
      "GHD",
      "Bodyweight",
      "No_Equipment",
      "Medball",
      "Plate",
      "Box",
      "Jump Rope",
      "Cardio Machine",
      "Rowing Machine",
      "Bike Erg",
      "Ski Erg",
      "Assault Bike",
      "Bench"
    ]
  },
  "black_list_equipment": {
    type: "str list",
    label: "Equipment Black List",
    default: [],
    description: "Select not allowed types equipment",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "Kettlebell",
      "Dumb Bell", 
      "Barbell",
      "Pullup Bar", 
      "Rings", 
      "Rope",
      "GHD",
      "Bodyweight",
      "No_Equipment",
      "Medball",
      "Plate",
      "Box",
      "Jump Rope",
      "Cardio Machine",
      "Rowing Machine",
      "Bike Erg",
      "Ski Erg",
      "Assault Bike",
      "Bench"
    ]
  },
  "must_include_equipment": {
    type: "str list",
    label: "Mandatory Equiptment",
    default: [],
    description: "Select not allowed types equipment",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "Kettlebell",
      "Dumb Bell", 
      "Barbell",
      "Pullup Bar", 
      "Rings", 
      "Rope",
      "GHD",
      "Bodyweight",
      "No_Equipment",
      "Medball",
      "Plate",
      "Box",
      "Jump Rope",
      "Cardio Machine",
      "Rowing Machine",
      "Bike Erg",
      "Ski Erg",
      "Assault Bike",
      "Bench"
    ]
  },

  "white_list_tags": {
    type: "list tag",
    label: "Whitelist Tags",
    default: [],
    description: "select tags to whitelist",
    selectOne: false,
    url: "/tags/",
    selectMany: true,
  },
  "black_list_tags": {
    type: "list tag",
    label: "Blacklist Tags",
    default: [],
    description: "select tags to whitelist",
    url: "/tags/",
    selectOne: false,
    selectMany: true,
  },
  "must_include_tags": {
    type: "list tag",
    label: "Mandatory Tags",
    default: [],
    description: "select tags to whitelist",
    url: "/tags/",
    selectOne: false,
    selectMany: true,
  },

  "white_list_parts": {
    type: "str list",
    label: "Whitelist Workout Parts",
    default: [],
    description: "Select Workout Parts to Whitelist",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "PART_CORE",
      "PART_METCON", 
      "PART_SKILL", 
      "PART_STRENGTH", 
      "PART_WARMUP",
    ]
  },
  "black_list_parts":  {
    type: "str list",
    label: "Blacklist Workout Parts",
    default: [],
    description: "Select not allowed wod parts",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "PART_CORE",
      "PART_METCON", 
      "PART_SKILL", 
      "PART_STRENGTH", 
      "PART_WARMUP",
    ]
  },
  "must_include_parts":  {
    type: "str list",
    label: "Select Mandatory Workout Parts",
    default: [],
    description: "Select not allowed types equipment",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "PART_CORE",
      "PART_METCON", 
      "PART_SKILL", 
      "PART_STRENGTH", 
      "PART_WARMUP",
    ]
  },

  "allowed_weights": { //done #min / max per movement
    type: "recursive",

    "movement":{
      type: "allowed_weights movement dict",
      label: "Weight range per movement",
      default: {},
      description: "set min and max weights for movements",
      url: "/movements/",
      query: {tag: "UNIT_WEIGHT"},
      selectOne: false,
      selectMany: true,
      choices: [
        5,6,7,7.5,8,9,10,12,12.5,15,16,20,22.5,24,25,27.5,30,32,32.5,35,37.5,40,
        42.5,43,45,47.5,50,55,60,65,70,75,80,85,90,95,100
      ],
          //"Snatch": [30, 60],
          //"DL": [80, 100]
      },
      "equipment":{ //#done #what eqipment do you have
        type: "allowed_weights tag dict",
        label: "Available weights",
        default: {},
        description: "which weightst do you have",
        selectOne: false,
        selectMany: true,
        choices: [
          0,4,5,6,7,7.5,8,9,10,12,12.5,15,16,20,22.5,24,25,27.5,30,32,32.5,35,37.5,40,
          42.5,43,45,47.5,50,55,60,65,70,75,80,85,90,95,100
        ],
        allowedValues: [ 
          "Kettlebell",
          "Dumb Bell",
          "Barbell", 
          "Medball",
        ]
          //EQUIP_KB: [16, 24, 32],
          //EQUIP_DB: [5, 7.5, 10, 12.5, 22.5],
          //EQUIP_BB: [25, 30, 32.5, 35, 40, 43, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
          //EQUIP_MEDBALL: [4, 6, 9],
      }
  },
  "std_weight": {
      type: "std_weight tag dict",
      label: "Standart Weight per Equpipment",
      default: {},
      description: "what is you std weight per equipment (Barbell ist calculated as avg between min and max)",
      selectOne: false,
      selectMany: true,
      choices: [4,5,6,7,7.5,8,9,10,12,12.5,15,16,20,22.5,24,25,30,32,32.5,35,40,],
      allowedValues: [ 
        "Kettlebell",
        "Dumb Bell",
        //"Barbell", 
        "Medball",
      ] 
      //"Dumb Bell": 22.5,
      //"Kettlebell": 24,
      //"Barbell": 40,
      //"Medball": 9,
      //"Plate": 10,
  },

  "difficulty": {//#done
    type: "str list",
    label: "Set Difficulty Level",
    default: ["INTERMEDIATE"],
    description: "defines category of movements",
    selectOne: false,
    selectMany: true,
    allowedValues: [ 
      "BEGINNER",
      "INTERMEDIATE", 
      "ADVANCED", 
    ]
      //"BEGINNER",// # includes movements too easy for some intermediate and advanced. eg jumping pullups, baded dips
      //"INTERMEDIATE", //# includes movements too hard for beginners. eg: pullups / dips / T2B
      //"ADVANCED", //# includes movements too hard for intermediate. eg BMU, RMU, Lsit, Legless
  },

  "movement_probabilities": {
    type: "movement prob list",
    label: "set Probabilities for Movements",
    default: [],
    description: "defines probability, that movement is part of workout",
    url: "/movements/",
    selectOne: false,
    selectMany: true,
    choices: [
      0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
      1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2,
      2.2, 2.4, 2.6, 2.8, 3, 3.5, 4, 5
    ],
      //["Pullups", 2.0],
      //["Burpees", 0.5],
      // 1.0 = base probability, 
      // 1.1 = 10% more often than average
      // 0.9 = 10% less often than average
  },

  "relative_reps":  {
    type: "number",
    label: "Number of Reps per Movement",
    default: 0,
    description: "0 = random, 0.1 = 10%, 1.0 = 100% of selected rep range",
    selectOne: true,
    selectMany: false,
    allowedValues: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  }, //0 = random, 0.1 = 10%, 1.0 = 100%.
  
  "relative_weight": {
    type: "number",
    label: "How much Weight",
    default: 0,
    description: "0 = dependant on reps, 0.1 = 10%, 1.0 = 100% of selected weight range",
    selectOne: true,
    selectMany: false,
    allowedValues: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  }, // 0 = dependant on weight, 0.1 = 10%, 1.0 = 100%.
  
  "base_probability": {
    type: "number",
    label: "Probability Scaling for Weight and Reps",
    default: 1,
    description: "0 = 0%, 1 = 50%. Probability scaling of weight, if weight is dependent on reps. 0 = less random, 1 = more random",
    selectOne: true,
    selectMany: false,
    allowedValues: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  }, // 0 = 0%, 1 = 50%

  "only_one_bb_weight": {
    type: "bool",
    label: "Use only one Barbell weight",
    default: true,
    description: "if more than one BB movemens, use the same weight for all movements",
  }, 
  "only_one_db_weight": {
    type: "bool",
    label: "Use only one Dumbbell weight",
    default: true,
    description: "if more than one DB movement, use the same weight for all movements",
  }, 
  "only_one_kb_weight": {
    type: "bool",
    label: "Use only one Kettlebell weight",
    default: true,
    description: "if more than one KB movement, use the same weight for all movements",
  }, 
  "only_one_mb_weight": {
    type: "bool",
    label: "Use Only One Medball Weight",
    default: true,
    description: "if more than one Medball movement, use the same weight for all movements",
  },

  "allow_each_movement_only_once": {
    type: "bool",
    label: "Allow Each Movement Only Once",
    default: true,
    description: "prevents the same movement from appearing in a workout more than one time per wod",
  }, 
  "allow_only_one_movement_from_each_tag": {
    type: "list tag",
    label: "Allow Only One Movement From Tag",
    default: [],
    description: "only one movement of these tags allowed in wod",
    url: "/tags/",
    selectOne: false,
    selectMany: true,
  },
  "do_not_combine_tags":{
    type: "list list tag",
    label: "Do Not Combine These Tags",
    default: [],
    description: "do not combine movements with these tags in a workout",
    url: "/tags/",
    selectOne: false,
    selectMany: true,
  },
      //[EQUIP_BB, EQUIP_DB],

  "do_not_combine_movements":{
    type: "list list movement",
    label: "Do Not Combine These Movements",
    default: [],
    description: "do not combine these movements in a workout",
    url: "/movements/",
    selectOne: false,
    selectMany: true,

  }
      //["BFB", "Burpees", "Burpees over DB", "Targe Burpees", "Box over Burpee"],
      //["Box Jump Over", "Box Jumps", "Box over Burpee"],
};

export const equipmentSettings = [
  "white_list_equipment", "black_list_equipment", "allowed_weights.equipment", 
  "black_list_movements",
];

export const generalSettings = [
  "black_list_movements", "white_list_movements",
  "black_list_tags", "white_list_tags", 
  "do_not_combine_tags", "do_not_combine_movements", "std_weight", "allowed_weights.movement", "difficulty",
  "white_list_equipment", "black_list_equipment"
];

export const wodSettings = [
  "black_list_movements", "white_list_movements", 
  "must_include_tags", "must_include_movements", "must_include_equipment",
  "white_list_equipment", "black_list_equipment",
  
  "black_list_tags", "white_list_tags", 
  "target_time_min",
  "wod_name",
  "max_number_of_movements", "min_number_of_movements",
  "min_wod_rounds", "max_wod_rounds", "wod_modes"
];

export const advendSettings = ["base_probability"];

export const settingTypes = {
  workout: wodSettings,
  equipment: equipmentSettings,
  general: generalSettings,
  
  advanced: advendSettings,
};

export function createInitialSettingState(map) {
  const initialState = {};
  //console.log(Object.keys(map));
  for (const e of Object.keys(map)) {
    //console.log(e);
    //console.log(map[e]);
    if (map[e].type.includes("list")) {
      initialState[e] = map[e].default;
      continue;
    }
    if (map[e].type.includes("number")) {
      initialState[e] = map[e].default;
      continue;
    }
    if (map[e].type.includes("str")) {
      initialState[e] = map[e].default;
      continue;
    }
    if (map[e].type.includes("bool")) {
      initialState[e] = map[e].default;
      continue;
    }
    if (map[e].type.includes("recursive")) {
      const copyMap = {...map[e]};
      delete copyMap.type;
      initialState[e] = createInitialSettingState(copyMap);
      continue;
    }
    if (map[e].type.includes("dict")) {
      initialState[e] = map[e].default;
      continue;
    }
  }
  return initialState;
}